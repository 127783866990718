import { Component, OnInit } from '@angular/core';
import { TemplateService} from "../../../services/template.service";
import { AlertService } from "../../../services/alert.service";
import { Router} from "@angular/router";
import { first} from "rxjs/operators";

@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.css']
})
export class AddTemplateComponent implements OnInit {
  name = null;
  adding = false;

  constructor(private templateService: TemplateService,
              private alertService: AlertService,
              private router: Router
              ) { }

  ngOnInit() {
  }

  addTemplate() {
    this.adding = true;
    const template = {name: this.name};
    this.templateService.addTemplate(template)
      .subscribe(
        data => {
          this.adding = false;
          this.alertService.success('New Template Added');
          this.router.navigate(['/templates']);
        },
        error => {
          this.alertService.error(error);
          this.adding = false;
        }
      );
  }

}

