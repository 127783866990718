import { Component, OnInit } from '@angular/core';
import {AlertService} from "../../services/alert.service";
import {NgxSmartModalService} from "ngx-smart-modal";
import {UserService} from "../../services/user.service";
import {first} from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  loading = false;
  addingUser = false;
  deletingUser = false;
  deleteUserConfirmModalId = 'deleteUser';
  selectedUserToEdit: any;
  selectedUserToDelete: any;
  userDetailModalId = 'userDetails';
  users: any;
  filteredUsers: any;
  editOption = false;
  deleteOption = false;
  deleting = false;
  selectedUser: any = null;
  loadingUserProperty = false;


  constructor(private alertService: AlertService,
              private modalService: NgxSmartModalService,
              private userService: UserService,
              private router: Router,
              private activeRouter: ActivatedRoute,
              private navService: NavigationService
              ) {
    const breadcrumbs = [
      {
        display: 'Users',
        link: 'users'
      },
    ];
    this.navService.setNavigation(breadcrumbs);
  }

  ngOnInit() {
    this.loading = true;
    this.getUsers();
    this.assignCopy();
  }

  getUsers() {
    this.userService.getUsers()
      .pipe(first())
      .subscribe(
        data => {
          this.users = this.filteredUsers = data;
          this.loading = false;
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
  filterUsers(user) {
    if (!user) {
      this.assignCopy();
    }
    this.filteredUsers = Object.assign([], this.users).filter(
      item => item.name.toLowerCase().indexOf(user.toLowerCase()) > -1
    );
  }
  assignCopy() {
    this.filteredUsers = Object.assign([], this.users);
  }

  addUser() {
    this.router.navigate(['/users/add']);
  }
  showEditOption(event) {
    event.stopPropagation();
    this.editOption = !this.editOption;
    this.deleteOption = false;
  }
  showDeleteOption(event) {
    event.stopPropagation();
    this.deleteOption = !this.deleteOption;
    this.editOption = false;
  }
  editUser(user) {
    this.userService.setUsertoEdit(user);
    this.router.navigate(['/users/edit/', user.id]);
  }

  showConfirmDeleteOption(user) {
    this.selectedUserToDelete = user;
    this.modalService.getModal(this.deleteUserConfirmModalId).open();
  }

  openUserDetails(user) {
    if (this.deleteOption || this.editOption) {
      if (this.deleteOption) {
        this.showConfirmDeleteOption(user);
      }
      if ( this.editOption) {
        this.editUser(user);
      }
      return;
    }
    this.selectedUser = user;
    this.modalService.getModal(this.userDetailModalId).open();
  }

  propertyClickDirector(property) {
    this.router.navigate(['/property/details/', property['id']],
      {queryParams: {
          suburb: property['suburb']['name'],
          suburbId : property['suburb']['id'],
          property : property['name']
      }});
  }

  removeUser(user) {
    this.deleting = true;
    this.filteredUsers = this.users.filter(u => u !== user);
    this.userService.deleteUser(user)
      .subscribe(
        data => {
          this.alertService.success('User deleted');
          this.deleting = false;
          this.closeModal(this.deleteUserConfirmModalId);
        },
        error => {
          this.alertService.error(error);
          this.deleting = false;
          this.closeModal(this.deleteUserConfirmModalId);
        }
      );
  }

  closeModal(id) {
    this.modalService.getModal(id).close();
  }




}
