
import { Injectable } from '@angular/core';

import {CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticateService } from './authenticate.service';

@Injectable()
export class Authguard {
    constructor(private authenticateService: AuthenticateService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentState: boolean = this.authenticateService.validateCheckin(state.url);
        if (!currentState) {
            this.router.navigate(['/login']);
        }
        return currentState;
    }

}
