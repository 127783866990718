import { Component, OnInit } from '@angular/core';
import { GetPropertiesService} from '../../../services/get-properties.service';
import { AlertService} from '../../../services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import {NavigationService} from "../../../services/navigation.service";

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.css']
})
export class PropertyCardComponent implements OnInit {

  property: any = {
    suburb : {
    },
    projects : [],
  };
  dimmer = false;
  propertyId = null;
  projectId = null;
  userRole = null;
  propertyAddress: any = null;


  constructor(private propertyService: GetPropertiesService,
              private router: Router,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private navService: NavigationService) {

    const breadcumbs = [
      {
        display: 'Suburbs',
        link: 'suburbs'
      },
      {
        display: this.route.snapshot.queryParams['suburb'],
        link: 'propertiesbysuburb/' + this.route.snapshot.queryParams['suburbId'],
        queryParams: {suburb: this.route.snapshot.queryParams['suburb']}
      },
      {
        display: this.route.snapshot.queryParams['property'],
        link: ''
      },
    ];
    this.navService.setNavigation(breadcumbs);
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    this.propertyId = routeParams.propertyId;
    this.dimmer = true;
    this.loadProperty(this.propertyId);
  }


  loadProperty(propertyId) {

    this.propertyService.getProperty(propertyId)
      .subscribe(
        data => {
          this.property = data.body;
          this.propertyAddress = this.property['address'];
          this.userRole = data.headers.get('User-Role');
          this.dimmer = false;
        },
        error => {
          this.alertService.error(error);
          this.dimmer = false;
        }
      );
  }

  createProject() {
    this.router.navigate(['/property', this.propertyId, 'project', 'add' ], {
      queryParams: { address: this.propertyAddress}
    });
  }
  openProject(project) {
    this.router.navigate(['/property', this.propertyId, 'project', project.id],
      {queryParams: {project : project.name}, queryParamsHandling: 'merge'});
  }

}
