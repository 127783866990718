import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../services/user.service";
import {Router} from "@angular/router";
import {AlertService} from "../../../services/alert.service";
import {FormGroup, FormArray, FormBuilder} from "@angular/forms";
import {debounce, debounceTime, distinctUntilChanged, map} from "rxjs/operators";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  roles: any;
  properties: any = [];
  loading = true;
  addUserForm: FormGroup;
  saving = false;
  checked = false;
  assignedProperties: any = [];
  constructor(private userService: UserService,
              private router: Router,
              private alertService: AlertService,
              private fb: FormBuilder) {

    this.addUserForm = this.fb.group({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: '',
      searchAvailableProperties: '',
      assignedProperties: this.fb.array([])
    });

  }

  ngOnInit() {
    this.loading = true;
    this.setData();
  }

  setData() {
    this.userService.getRoles()
      .subscribe(
        roles => {
          this.roles = roles;
          this.loading = false;
        }
      );
  }

  get userAssignedProperties() {
    return this.addUserForm.get('assignedProperties') as FormArray;
  }

  get searchProperty () {
    return this.addUserForm.get('searchAvailableProperties');
  }


  toggleCheckProperty(property) {
    property.checked = !property.checked;
    if (property.checked) {
     this.assignedProperties.push(property);
      this.userAssignedProperties.push(
        this.fb.group({
          propertyId: property['id'],
          chooseProperty: true
        })
      );
    } else {
      this.assignedProperties = this.assignedProperties.filter(u => u.id !== property.id);
      this.userAssignedProperties
        .removeAt(
          this.userAssignedProperties.value
            .findIndex(userAssginedProperty => userAssginedProperty.propertyId === property.id));
    }
    this.searchProperty.setValue('');
  }

  saveUser() {
    this.saving = true;
    this.userService.saveUser(this.addUserForm.value)
      .subscribe(
        data => {
          this.saving = false;
          this.router.navigate(['/users']);
          this.alertService.success('New User added');
        },
        error => {
          this.saving = false;
          this.alertService.error(error);
        }
      );
  }

  searchAvailableProperties(property) {
    this.userService.getAvailableProperties(property)
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(
      properties => {
        this.properties = properties;
        this.properties.forEach(availableProperty => {
          if (this.assignedProperties.filter(assignedProperty => assignedProperty['id'] == availableProperty['id']).length > 0) {
            availableProperty['checked'] = true;
          } else {
            availableProperty['checked'] = false;
          }
        });
      },
      error => {
        this.alertService.error('Something went wrong');
      }
    );
  }
}
