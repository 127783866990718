import { TemplateMilestone, TemplateTask, Milestones } from './../models/data-models';

import { BaseHttpService } from './http/base/base-http.service';

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable, forkJoin, zip} from 'rxjs';
import { Template, TemplateCategory } from '../models/data-models';
import { map, tap } from 'rxjs/operators';
import { AuthenticateService } from './login/authenticate.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends BaseHttpService {
  private resource="";

  selectedMileStoneToEdit = null;
  selectedTemplateToEdit = null;
  chooseTemplateFromProject = false;


  constructor(protected http:HttpClient,protected authsvc:AuthenticateService) {
    super();
    this.setResource("templates");

  }
  private results;

  setChooseTemplateFromProject(projectId) {
    this.chooseTemplateFromProject = projectId;
  }

  getChooseTemplateFromProject() {
    return this.chooseTemplateFromProject;
  }

  getcategoriesAndTemplates():Observable<any[]>{
    let httpObservers=[];
    httpObservers.push(this.getTemplates());
    httpObservers.push(this.getCategories());

    return  forkJoin(httpObservers);

  }

  search(searchString:string):Observable<any[]>{

    return this.http.get<Template[]>(this.baseurl+"/templates?name_like="+searchString);
  }

  getTemplates(): Observable<HttpResponse<Object>> {
    this.setResource('templates');
    return this.http.get<HttpResponse<Object>>(this.finalurl, {observe: 'response'});

  }

  getCategories():Observable<TemplateCategory[]>{
    this.setResource("templatetypes") ;

    return this.http.get<Template[]>(this.finalurl,this.authsvc.getJWTHeader())
      .pipe(map((x:any[])=>{
          let first=new TemplateCategory();
          first["Id"]=null;
          first["Name"]="All";

          x.splice(0,0,first);
          return x.map((item)=>{
              let f=new TemplateCategory();
              return Object.assign(f,
                {"id":item.Id,"name":item.Name,
                }
              )
            }
          )
        })
      )

  }

  getcategoriesAndSingleTemplate(templateId:string):Observable<any[]>{
    let httpObservers=[];
    httpObservers.push(this.getTemplate(templateId));
    httpObservers.push(this.getCategories());

    return  forkJoin(httpObservers);

  }
  getTemplate(templateId:string):Observable<Template>{
    this.setResource("templates/"+templateId) ;

    return this.http.get<Template>(this.finalurl,this.authsvc.getJWTHeader())

      .pipe(map((x:any)=>{
          let f=new Template();

          return Object.assign(f,
            {"id":x.Id,"name":x.Name, "categoryId":x.Type }
          )
        })

      )
      ;

  }

  copyTemplate(templateToCopy:Template):Observable<Template>{
    if(templateToCopy==null ) return null;
    this.setResource("CopyTemplate/"+templateToCopy.id);
    let updateResourceURL = this.finalurl;
    return this.http.get<Template>(this.finalurl,this.authsvc.getJWTHeader())
      .pipe(map((x:any)=>{
          let f=new Template();

          return Object.assign(f,
            {"id":x.Id,"name":x.Name, "categoryId":x.Type }
          )
        })
      );
  }

  deleteTemplate(template) {
    this.setResource('templates/' + template.id);
    return this.http.delete(this.finalurl);
  }

  updateTemplate(templateToSave:Template):Observable<any>|null {
    if(templateToSave==null ) return null;
    const body={name:templateToSave.name, id:templateToSave.id, Type:templateToSave.categoryId};
    this.setResource("EditTemplate");
    let updateResourceURL = this.finalurl;
    return this.http.post(updateResourceURL,body,this.authsvc.getJWTHeader());//{headers});
  }

  addTemplate(template):Observable<any>|null {
    this.setResource("templates");
    return this.http.post(this.finalurl, template);
  }

  addTemplateMilestone(roomToSave:string, templateId:string):Observable<TemplateMilestone>|null {
    if(roomToSave==null ) return null;
    this.setResource("AddRoomToTemplate");
    const body={name:roomToSave, templateId:templateId };
    return this.http.post<Template>(this.finalurl,body, this.authsvc.getJWTHeader())
      .pipe(map((item:any)=>{

          let f=new TemplateMilestone(null,"");

          return Object.assign(f,
            {"id":item.Id,"name":item.Name,
              "templateId":item["templateId"],
              "active":item["Active"],
              "sortnum":item["sortNum"],

            }
          )
        })
      )
  }

  copyTemplateMilestone(roomIDToCopy:string, templateId:string):Observable<TemplateMilestone>|null {
    if(roomIDToCopy==null ) return null;
    this.setResource("AddExistingRoomToTemplate");
    const body = {id :roomIDToCopy, templateId:templateId };
    return this.http.post<Template>(this.finalurl,body, this.authsvc.getJWTHeader())
      .pipe(map((item:any)=>{

        let f=new TemplateMilestone(null,"");

        return Object.assign(f,
          {"id":item.Id,"name":item.Name,
            "templateId":item["templateId"],
            "active":item["Active"],
            "sortnum":item["sortNum"],

          }
        )
      }))
  }

  copyTemplateMilestoneAndTasks(choosenTemplate, projectId) {
    this.setResource('templates/' + choosenTemplate + '/copy/' + projectId);
    return this.http.post(this.finalurl, {});
  }
  getTemplateMilestones(templateId:string):Observable<TemplateMilestone[]> {
    this.setResource("templates/"+templateId +"/templatemilestones");


    return this.http.get<TemplateMilestone[]>(this.finalurl,this.authsvc.getJWTHeader() )//{params})

      .pipe(map((x:any[])=>{
          return x.map((item)=>{
              let f=new TemplateMilestone(null,"");

              return Object.assign(f,
                {"id":item.id,"name":item.name,
                  "templateId":item["templateId"],
                  "active":item["active"],
                  "sortnum":item["sortNum"],

                }
              )
            }
          )
        })
      )
  }
  getTemplateMilestone(templateId:string, templateRoomID:string):Observable<TemplateMilestone> {
    this.setResource("template/"+templateId+"/rooms/"+templateRoomID);
    return this.http.get<TemplateMilestone>(this.finalurl, this.authsvc.getJWTHeader())

      .pipe(map((item:any)=>{

          let f=new TemplateMilestone(null,"");
          return Object.assign(f,
            {"id":item.id,
              "name":item.name,
              "templateId":item["templateId"],
              "active":item["active"],
              "sortnum":item["sortnum"]
            }
          );


        })
      )

      ;

  }

  updateTemplateMilestone(templateRoomToSave:TemplateMilestone):Observable<any>|null {
    this.setResource("EditTemplateRoom") ;
    if(templateRoomToSave==null ) return null;
    const body={
      "name":templateRoomToSave.name,
      "id":templateRoomToSave.id,
      "templateId":templateRoomToSave.templateId,
      "sortnum":templateRoomToSave.sortNum,
      "active":templateRoomToSave.active
    };

    let updateResourceURL = this.finalurl;//+"/"+templateRoomToSave.id.toString();
    return this.http.post(updateResourceURL,body,this.authsvc.getJWTHeader());// {headers});
  }

  addTemplateTask(sectionToSave:TemplateTask,roomid:string, templateId:string):Observable<TemplateTask>|null {
    if(sectionToSave==null ) return null;
    this.setResource("AddSectionToTemplate");
    const body={
      "name":sectionToSave.name,
      "roomid":sectionToSave.milestoneId,
      "templateId":sectionToSave.templateId,
      "sortnum":sectionToSave.sortNum,
      "active":sectionToSave.active
    };
    return this.http.post<TemplateTask>(this.finalurl,body, this.authsvc.getJWTHeader())
      .pipe(map((item:any)=>{

          let f=new TemplateTask();

          return Object.assign(f,
            {
              id        :item.Id,
              name      :item.Name,
              roomid    :item["RoomId"],
              templateId:item["templateId"],
              sortnum   :item["SortNum"],
              active    :item["Active"]
            }
          )

        })
      )

  }
  updateTemplateTask(templateSectionToSave:TemplateTask):Observable<any>|null {
    this.setResource("EditTemplateSection") ;
    if(templateSectionToSave==null ) return null;
    const body={
      "name":templateSectionToSave.name,
      "roomId":templateSectionToSave.milestoneId,
      "id":templateSectionToSave.id,
      "templateId":templateSectionToSave.templateId,
      "sortnum":templateSectionToSave.sortNum,
      "active":templateSectionToSave.active
    };

    let updateResourceURL = this.finalurl;//+"/"+templateSectionToSave.id.toString();
    return this.http.post(updateResourceURL,body,this.authsvc.getJWTHeader());//{headers});
  }

  getTemplateTask(templateSectionID:string, sectionID:string):Observable<TemplateTask>{
    this.setResource("template/"+templateSectionID+"/tasks/"+sectionID) ;

    return this.http.get<TemplateTask>(this.finalurl, this.authsvc.getJWTHeader())

      .pipe(map((item:any)=>{
          let f=new TemplateTask();

          return Object.assign(f,
            {
              id            :item.Id,
              name          :item.name,
              milestoneId   :item["milestoneId"],
              templateId    :item["templateId"],
              sortnum       :item["sortNum"],
              active        :item["active"]
            }
          )

        })
      )

      ;

  }
  getTemplateTasks(templateId:string):Observable<TemplateTask[]> {

    this.setResource("templates/"+templateId+"/templateTasks");

    return this.http.get<TemplateTask[]>(this.finalurl,this.authsvc.getJWTHeader())//{params})
      .pipe(map((x:any[])=>{
        return x.map((item)=>{
            let f=new TemplateTask();

            return Object.assign(f,
              {"id":item.id,"name":item.name,
                "milestoneId":item["milestoneId"],
                "templateId":item["templateId"],
                "sortnum":item["sortNum"],
                "active":item["active"]
              }
            )
          }
        )
      }))

  }

  getTemplateTasksByMilestone(roomID:string,  templateId:string):Observable<TemplateTask[]>{
    this.setResource("template/"+templateId+"/rooms/"+roomID+"/tasks");


    return this.http.get<TemplateTask[]>(this.finalurl,this.authsvc.getJWTHeader())//{params})
      .pipe(map((x:any[])=>{
          return x.map((item)=>{
              let f=new TemplateTask();

              return Object.assign(f,
                {"id":item.id,"name":item.name,
                  "milestoneid":item["milestoneId"],
                  "templateId":item["templateId"],
                  "sortnum":item["sortNum"],
                  "active":item["active"]
                }
              )
            }
          )
        })
      )
  }

  getTemplateData(templateId:string){
    let httpObservers=[];

    httpObservers.push(this.getTemplateMilestones(templateId)
      .pipe(map((data:TemplateMilestone[])=>data)));
    httpObservers.push(this.getTemplateTasks(templateId)
      .pipe(map((data:TemplateTask[])=>data)));

    httpObservers.push(this.getTemplate(templateId)
      .pipe(map((data:Template)=>data)));

    return forkJoin(httpObservers);
  }

  getTemplatesData(templateId) {
    this.setResource('templates/' + templateId);
    return this.http.get(this.finalurl);
  }

  updateMilestoneSort(milestones, templateId) {
    this.setResource('template-milestones/sort/' + templateId);
    return this.http.post(this.finalurl, milestones);
  }

  setSelectedMileStoneToEdit(milestone) {
    this.selectedMileStoneToEdit = milestone;
  }

  getSelectedMileStoneToEdit() {
    return this.selectedMileStoneToEdit;
  }

  setSelectedTemplateToEdit(template) {
    this.selectedTemplateToEdit = template;
  }
  getSelectedTemplateToEdit() {
    return this.selectedTemplateToEdit;
  }

  saveMileStone(milestoneWithTasks, milestoneId) {
    this.setResource('template-milestones/' + milestoneId);
    return this.http.put(this.finalurl, milestoneWithTasks);
  }

  saveTemplate(template, id) {
    this.setResource('templates/'  + id);
    return this.http.put(this.finalurl, template);
  }

  delteteMilestone (milestone) {
    this.setResource('template-milestones/' + milestone.id);
    return this.http.delete(this.finalurl);
  }

  associateTaskToMilestones(templateMilestones:TemplateMilestone[], templateTasks:TemplateTask[]){
    for(var i=0;i<templateMilestones.length;i++){

      templateMilestones[i].tasks = templateTasks.filter((assoc_task:TemplateTask)=>
        assoc_task.milestoneId==templateMilestones[i].id);
    }
    return templateMilestones;
  }

  deleteTemplateMilestone(roomToDelete:TemplateMilestone){
    this.setResource("DeleteTemplateRoom");
    const body={
      "Id":roomToDelete.id,
      "templateId":roomToDelete.templateId};
    return this.http.post(this.finalurl, body, this.authsvc.getJWTHeader())
      .pipe(map((item:any)=>{
        if(item=="Success" || item ==true){
          return true;
        }
        else{
          return false;
        }

      }))
  }
  copyTasks(newroomid, sectionsToAdd:TemplateTask[], templateId):Observable<TemplateTask[]>{
    let httpObservers=[];

    for(let i=0;i<sectionsToAdd.length;i++){
      let oldSection=sectionsToAdd[i];
      let newSection:TemplateTask=new TemplateTask(templateId);
      newSection.name=oldSection.name;
      newSection.milestoneId=newroomid;
      newSection.templateId=oldSection.templateId;
      httpObservers.push(this.addTemplateTask(newSection,newroomid,templateId ));


    }


    return forkJoin(httpObservers);
  }
  deleteTemplateTask(sectionToDelete:TemplateTask):Observable<any>{
    this.setResource("DeleteTemplateSection");
    const body={
      "Id":sectionToDelete.id,
      "templateId":sectionToDelete.templateId
    };
    return this.http.post(this.finalurl, body, this.authsvc.getJWTHeader())
      .pipe(map((item:any)=>{
          if(item=="Success" || item ==true){
            return true;
          }
          else{
            return false;
          }

        })
      )
  }

  saveSort(milestones:TemplateMilestone[]):Observable<any>{
    var dataMilstoneSet:TemplateMilestone[]=[];
    var dataTaskSet:TemplateTask[]=[];
    var templateId="";
    let httpRequests=[] ;
    for(var i=0;i<milestones.length;i++){


      var dataMilestoneitem:TemplateMilestone= new TemplateMilestone(
        milestones[i].id,milestones[i].name);


      dataMilestoneitem.sortNum       = (i+1);

      dataMilestoneitem.templateId    = milestones[i].templateId;
      dataMilestoneitem.active        = milestones[i].active;

      dataMilestoneitem.start_date    = null;
      dataMilestoneitem.end_date    = null;



      templateId    = milestones[i].templateId

      dataMilstoneSet.push(dataMilestoneitem);

      let tasks:TemplateTask[]=milestones[i].tasks;
      for(var j=0;j<tasks.length;j++){

        var dataTaskItem:TemplateTask={
          id            : tasks[j].id,
          sortNum       : (j+1),
          milestoneId   : tasks[j].milestoneId,
          templateId    : tasks[j].templateId,
          name          : tasks[j].name

        };

        dataTaskSet.push(dataTaskItem);


      }



    }





    //return this.http.post<RatingComment>(this.finalurl,body, this.authsvc.getJWTHeader());
    const milestoneJSON=JSON.stringify(dataMilstoneSet);

    this.setResource("sortTemplateMiletstones");
    httpRequests.push( this.http.post<boolean>(this.finalurl,milestoneJSON,this.authsvc.getJWTHeader()));

    const tasksJSON=JSON.stringify(dataTaskSet);
    if(dataTaskSet.length>0 ){
      this.setResource("sortTemplateTasks");
      httpRequests.push( this.http.post<boolean>(this.finalurl,tasksJSON,this.authsvc.getJWTHeader()));
    }


    return forkJoin(httpRequests); //COMBINE the observables to get a single response

  }


  /*
  PUT is for updating and always needs two things
   a resource e.g. folders/1  or folders/2 etc

   Ensure headers has the following  Content-Type= application/json

   pass the request and headers to the PUT Request

  */

  /*
  POST will create a record on the server
  */

}
