import { Injectable } from '@angular/core';
import {BaseHttpService} from './http/base/base-http.service';
import { HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddEditSuburbsService extends BaseHttpService {

  constructor(private http: HttpClient) {
    super();
  }
  addSuburb(suburb) {
    this.setResource('suburbs');
    return this.http.post(this.finalurl, suburb);
  }

  removeSuburb(suburb) {
    this.setResource('suburbs/' + suburb);
    return this.http.delete(this.finalurl);
  }
}
