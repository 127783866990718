import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {UserService} from "../../../services/user.service";
import {Router} from "@angular/router";
import {AlertService} from "../../../services/alert.service";
import {NavigationService} from "../../../services/navigation.service";
import {first} from "rxjs/operators";
import {RegisterService} from "../../../services/login/register.service";

@Component({
  selector: 'app-register-builder',
  templateUrl: './register-builder.component.html',
  styleUrls: ['./register-builder.component.css']
})
export class RegisterBuilderComponent implements OnInit {

  loading = false;
  register = false;
  addUserForm: FormGroup;

  constructor(private userService: UserService,
              private router: Router,
              private alertService: AlertService,
              private navService: NavigationService,
              private registerService: RegisterService,
              private fb: FormBuilder) {

    this.addUserForm = this.fb.group({
      name: '',
      email: '',
      password: '',
      password_confirmation: ''
    });

    const breadcrumbs = [
      {
        display: 'Builders',
        link: 'builders'
      },
    ];

    this.navService.setNavigation(breadcrumbs);
  }

  ngOnInit() {

  }

  saveUser() {
    this.register = true;
    const builder = this.addUserForm.value;
    this.registerService.register(builder)
      .subscribe(
      data => {
        this.register = false;
        this.router.navigate(['/builders']);
      },
      error => {
        this.register = false;
        this.alertService.error(error);
      }
    );
  }

}
