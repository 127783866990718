import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  navigation: any;

  constructor() { }

  get navigations() {
    return this.navigation;
  }

  setNavigation(navigation) {
    this.navigation = navigation;
  }
}
