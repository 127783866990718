import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../../services/alert.service";
import {UserService} from "../../services/user.service";
import {NavigationService} from "../../services/navigation.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthenticateService} from "../../services/login/authenticate.service";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  changePasswordForm: FormGroup;
  saving = false;

  constructor(private alertService: AlertService,
              private userService: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private navService: NavigationService,
              private authService: AuthenticateService,
              private  fb: FormBuilder) {

    const breadcrumbs = [
      {
        display: 'Profile',
        link: 'profile'
      },
    ];
    this.navService.setNavigation(breadcrumbs);

    this.profileForm = this.fb.group({
      name: '',
      userId: '',
    });

    this.changePasswordForm = this.fb.group({
      userId: '',
      old_password: '',
      password: '',
      password_confirmation: ''
    });
  }

  ngOnInit() {
    this.setProfile();
  }

  setProfile() {
    const user = this.authService.getLoggedInUser();
    this.profileForm.patchValue({
      name : user['name'],
      userId: user['userId']
    });
    this.changePasswordForm.patchValue({
      userId: user['userId']
    });
  }

  updateProfile() {
    this.saving = true;
    const profile = this.profileForm.value;
    this.userService.updateProfile(profile)
      .subscribe(
        data => {
          this.authService.setLoggedInUser(data);
          this.alertService.success('Saved');
          this.saving = false;
        },
        error => {
          this.alertService.error(error);
          this.saving = false;
        }
      );
  }

  changePassword() {
    this.saving = true;
    const password = this.changePasswordForm.value;
    this.userService.changePassword(password)
      .subscribe(
        data => {
          this.saving = false;
          this.alertService.success('Password changed');
          this.logout();
        },
        error => {
          this.saving = false;
          this.alertService.error(error);
        }
      );
  }

  logout() {
    sessionStorage.setItem('isLoggedIn', 'false');
    sessionStorage.setItem('blank_one', '');
    this.authService.setLoggedInUser(null);
    this.router.navigate(['/login']);
  }


  changeTabs(tab) {

    if (tab === 'profile') {
      document.getElementById('profile-tab-menu').classList.add('active');
      document.getElementById('profile-tab').classList.add('active');
      document.getElementById('password-tab-menu').classList.remove('active');
      document.getElementById('password-tab').classList.remove('active');
    } else {
      document.getElementById('profile-tab-menu').classList.remove('active');
      document.getElementById('profile-tab').classList.remove('active');
      document.getElementById('password-tab-menu').classList.add('active');
      document.getElementById('password-tab').classList.add('active');
    }

  }

}
