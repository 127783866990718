import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AlertService} from "../../services/alert.service";
import {NavigationService} from "../../services/navigation.service";
import {SettingsService} from "../../services/settings.service";

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  configuration: FormGroup;
  saving = false;
  config: any;
  loading = false;


  constructor(private alertService: AlertService,
              private fb: FormBuilder,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private navigationService: NavigationService,
              private configService: SettingsService) {

    const breadcrumbs = [
      {
        display: 'Settings',
        link: 'config'
      }
    ];
    this.navigationService.setNavigation(breadcrumbs);

    this.configuration = this.fb.group({
      'emailNotification': false
    });
  }

  ngOnInit() {
    this.setData();
  }

  setData() {
    this.loading = true;
    this.configService.getConfig().subscribe(
      data => {
        this.config = data;
        const config = this.config.filter(u => u['configuration'] === 'email_notifications');
        this.configuration.patchValue({
          'emailNotification': config[0]['value'] == 1
        });
        this.loading = false;
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      }
    );
  }

  saveConfiguration() {
    this.saving = true;
    const config = this.configuration.value;
    this.configService.saveConfig(config)
      .subscribe(
        data => {
          this.alertService.success('Saved');
          this.saving = false;
        },
        error => {
          this.alertService.error(error);
          this.saving = false;
        }
      );
  }

}
