import { Component, OnInit } from '@angular/core';
import { GetPropertiesService } from '../../../services/get-properties.service';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { AlertService} from '../../../services/alert.service';
import {first, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';


@Component({
  selector: 'app-edit-property',
  templateUrl: './edit-property.component.html',
  styleUrls: ['./edit-property.component.css']
})
export class EditPropertyComponent implements OnInit {

  name = null;
  address = null;
  picture: File = null;
  suburbId = null;
  propertyId = null;
  propertyImage = null;
  loading = false;
  saving = false;

  constructor(private propertyService: GetPropertiesService,
              private router: Router,
              private route: ActivatedRoute,
              private alertService: AlertService,
              ) { }

  ngOnInit() {
    this.loading = true;
    const routeParams = this.route.snapshot.params;
    this.suburbId = routeParams.suburbId;
    this.getPropertyById();

  }

  getPropertyById() {
    this.route.paramMap
      .pipe(switchMap((params: ParamMap) => {
        const propertyId = params.get('propertyId');
        return of(propertyId);
      }))
      .subscribe(propertyId => {
        this.getProperty(propertyId);
      });
  }

  getProperty(propertyId) {
    this.propertyService.getProperty(propertyId)
      .subscribe(
        data => {
            this.name = data.body['name'];
            this.address = data.body['address'];
            this.picture = this.propertyImage =  data.body['picture'];
            this.propertyId = data.body['id'];
            this.loading = false;
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  onFileSelected(event) {
    this.picture = <File> event.target.files[0];
    const image = new FormData();
    image.append('picture', this.picture);
    this.propertyService.changeImage(image, this.propertyId)
      .subscribe(
        data => {
          this.propertyImage = data['url'];
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  editProperty() {
    // const propertyData = new FormData();
    this.saving = true;
    const propertyData = {name: this.name, address: this.address, suburb_id: this.suburbId, id: this.propertyId }
    this.propertyService.editProperty(propertyData)
      .subscribe(
        data => {
          this.saving = false;
          this.alertService.success('Property updated');
          this.router.navigate(['/propertiesbysuburb', this.suburbId]);
        },
        error => {
          this.saving =  false;
          this.alertService.error(error);
        }
      );
  }


}
