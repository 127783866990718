import { Component, OnInit } from '@angular/core';
import { AlertService} from "../../../services/alert.service";
import { GetProjectService} from "../../../services/get-project.service";
import { Router, ActivatedRoute} from "@angular/router";
import { FormGroup, FormBuilder} from "@angular/forms";
import {Project} from "../../../models/data-models";
import {IMyDpOptions} from 'mydatepicker';



@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnInit {

  selectedProjectToEdit: Project;
  propertyAddress: any = null;
  editProjectForm: FormGroup
  loading = false;
  saving = false;


  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'yyyy-mm-dd',
  };

  constructor(private fb: FormBuilder,
              private alertService: AlertService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private projectService: GetProjectService
              ) {
    this.selectedProjectToEdit = this.projectService.selectedProject;
    this.propertyAddress = this.selectedProjectToEdit.name.split('-')[1];

    this.editProjectForm = this.fb.group({
      projectId: '',
      propertyId: '',
      name: '',
      property_address: '',
      start_date: '',
      end_date: '',
      status: '',
    });
  }

  ngOnInit() {
    this.setSelectedProjectToEdit();
  }

  setSelectedProjectToEdit() {
    const projectStartDate = new Date(this.selectedProjectToEdit.start_date);
    const projectEndDate = new Date(this.selectedProjectToEdit.end_date);
    this.editProjectForm.patchValue({
      projectId: this.selectedProjectToEdit.id,
      propertyId: this.selectedProjectToEdit.property_id,
      name: this.selectedProjectToEdit.name.split('-')[0],
      property_address: this.selectedProjectToEdit.name.split('-')[1],
      status: this.selectedProjectToEdit.status,
      start_date: {
        date: {
          year: projectStartDate.getFullYear(),
          month: projectStartDate.getMonth() + 1,
          day: projectStartDate.getDate()
        }
      },
      end_date: {
        date: {
          year: projectEndDate.getFullYear(),
          month: projectEndDate.getMonth() + 1,
          day: projectEndDate.getDate()
        }
      },
    });
  }

  saveProject() {
    this.saving = true;
    const project = this.editProjectForm.value;
    this.projectService.saveProject(project)
      .subscribe(
        data => {
          this.saving = false;
          this.alertService.success('Saved');
          this.router.navigate(['/runningprojects']);
        },
        error => {
          this.saving = false;
          this.alertService.error(error);
        }
      );
  }

}
