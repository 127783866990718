import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { TemplateService} from '../../../../services/template.service';
import { Router, ActivatedRoute} from '@angular/router';
import { AlertService} from '../../../../services/alert.service';
import {first} from 'rxjs/operators';


@Component({
  selector: 'app-edit-milestone',
  templateUrl: './edit-milestone.component.html',
  styleUrls: ['./edit-milestone.component.css']
})
export class EditMilestoneComponent implements OnInit {

  milestone = null;
  error = null;
  dimmer = false;
  savingMileStone = false;
  milestoneId = null;
  templateId = null;

  constructor(private fb: FormBuilder,
              private templateService: TemplateService,
              private alertService: AlertService,
              private router: Router,
              private route: ActivatedRoute
              ) {}

    mileStoneForm: FormGroup;

  ngOnInit() {
    this.dimmer = true;
    this.mileStoneForm = this.fb.group({
      milestoneName: [],
      tasks : this.fb.array([this.fb.group({task: '', id: ''})])
    });
    this.milestoneId = this.route.snapshot.paramMap.get('milestoneId');
    this.templateId = this.route.snapshot.paramMap.get('templateId');
    this.loadData();
  }

  loadData() {
    this.milestone = this.templateService.getSelectedMileStoneToEdit();
    this.milestone.tasks.forEach(task =>  {
      const taskForm = this.fb.group({task: '', id: ''});
      taskForm.patchValue({task: task.name, id: task.id})
      this.mileStoneTasks.push(taskForm);
    })
    this.dimmer = false;

  }
  get mileStoneTasks() {
    return this.mileStoneForm.get('tasks') as FormArray;
  }


  addTask() {
    this.mileStoneTasks.push(this.fb.group({task: '', id: ''}));
  }

  deleteTask(index) {
    this.mileStoneTasks.removeAt(index);
  }
  saveMilestone() {
    this.savingMileStone = true;

    this.templateService.saveMileStone(this.mileStoneForm.value, this.milestoneId)
      .subscribe(
        data => {
          this.savingMileStone = false;
          this.router.navigate(['/templateAdmin', this.templateId]);
        },
        error => {
          this.alertService.error(error);
          this.savingMileStone = false;
        }
      );
  }


}
