import { Component, OnInit } from '@angular/core';
import { GetPropertiesService } from '../../../services/get-properties.service';
import { Router, ActivatedRoute} from '@angular/router';
import { AlertService} from '../../../services/alert.service';
import {first, switchMap} from 'rxjs/operators';


@Component({
  selector: 'app-add-edit-property',
  templateUrl: './add-edit-property.component.html',
  styleUrls: ['./add-edit-property.component.css']
})
export class AddEditPropertyComponent implements OnInit {

  name = '';
  address = '';
  picture: File = null;
  suburbId = null;
  adding = false;

  constructor(private propertyService: GetPropertiesService,
              private router: Router,
              private route: ActivatedRoute,
              private alertService: AlertService) { }

  ngOnInit() {

    const routeParams = this.route.snapshot.params;
    this.suburbId = routeParams.id;
  }

  onFileSelected(event) {
   this.picture = <File> event.target.files[0];
  }

  addProperty() {
    this.adding = true;
    const propertyData = new FormData();
    // const propertyData = {name: this.name, address: this.address, suburb_id: this.suburbId, picture: this.picture }
    propertyData.append('name', this.name);
    propertyData.append('address', this.address);
    propertyData.append('suburb_id', this.suburbId);
    if (this.picture) {
      propertyData.append('picture', this.picture, this.picture.name);
    }
    // this.propertyService.addProperty()
    this.propertyService.addProperty(propertyData)
      .subscribe(
        data => {
          this.adding = false;
          this.alertService.success('New Property Added');
          this.router.navigate(['/propertiesbysuburb', this.suburbId]);
        },
        error => {
          this.adding = false;
          this.alertService.error(error);
        }
      );
  }

}
