import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

/*in memory modules for dev environment only */
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { LocalInMemoryDbService } from './services/http/mock/in-memory-db.service';
import { environment } from '../environments/environment';
/* end of testing memory modules */

import { FormsModule } from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import { AppComponent } from './app.component';
import { ActivebarComponent } from './common/activebar/activebar.component';
import { MenuComponent } from './common/menu/menu.component';
import { LoginComponent } from './common/login/login.component';
import { NoComponentFoundComponent } from './common/no-component-found/no-component-found.component';
import { SplashComponent } from './common/splash/splash.component';
import { Authguard } from './services/login/authguard';
import { SuburbsComponent } from './views/suburbs/suburbs.component';



import { PropertiesBySuburbComponent } from './views/properties-by-suburb/properties-by-suburb.component';
import { RunningProjectsComponent } from './views/running-projects/running-projects.component';
import { ProjectCardComponent } from './views/project-card/project-card.component';
import { ProjectDetailsComponent } from './views/project-details/project-details.component';
import { TemplateComponent } from './views/template/template.component';
import { TemplateListingComponent } from './views/template-listing/template-listing.component';
import { TemplateEditComponent } from './forms/template-edit/template-edit.component';
import { ProjectRoutingModule } from './routes.module';
import { MilestoneComponent } from './views/milestone/milestone.component';
import { TaskComponent } from './views/task/task.component';
import { TaskHeaderComponent } from './views/task-header/task-header.component';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import { SignupComponent } from './common/signup/signup.component';
import { AlertComponent } from './common/alert/alert.component';
import { AddEditComponent } from './views/suburbs/add-edit/add-edit.component';

import { TemplateCategoryListComponent } from './views/template-category/template-category-list/template-category-list.component';
import { AddTemplateCategoryComponent } from './views/template-category/add-template-category/add-template-category.component';
import { EditTemplateCategoryComponent } from './views/template-category/edit-template-category/edit-template-category.component';
import { AddTemplateComponent } from './views/template/add-template/add-template.component';
import { AddMilestoneComponent } from './views/template/milestone/add-milestone/add-milestone.component';
import { ReactiveFormsModule} from '@angular/forms';
import { EditMilestoneComponent } from './views/template/milestone/edit-milestone/edit-milestone.component';
import { EditTemplateComponent } from './views/template/edit-template/edit-template.component';
import {RequestInterceptor} from './interceptors/http.interceptor';
import { AddEditPropertyComponent } from './views/properties-by-suburb/add-edit-property/add-edit-property.component';
import { EditPropertyComponent } from './views/properties-by-suburb/edit-property/edit-property.component';
import { EditSuburbComponent } from './views/suburbs/edit-suburb/edit-suburb.component';
import { PropertyCardComponent } from './views/properties-by-suburb/property-card/property-card.component';
import { CreateProjectComponent } from './views/project/create-project/create-project.component';
import { ViewProjectComponent } from './views/project/view-project/view-project.component';
import { AddMilestonesProjectComponent } from './views/project/add-milestones-project/add-milestones-project.component';
import { DisableControlDirective } from './disable-control.directive';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {JwtModule} from '@auth0/angular-jwt';
import { MyDatePickerModule } from 'mydatepicker';
import { UserComponent } from './views/user/user.component';
import { AddUserComponent } from './views/user/add-user/add-user.component';
import { EditUserComponent } from './views/user/edit-user/edit-user.component';
import {ToastrModule} from 'ng6-toastr-notifications';
import { EditProjectComponent } from './views/project/edit-project/edit-project.component';
import { BreadcrumbComponent } from './common/breadcrumb/breadcrumb.component';
import { BuilderComponent } from './views/builder/builder.component';
import { RegisterBuilderComponent } from './views/builder/register-builder/register-builder.component';
import { EditBuilderComponent } from './views/builder/edit-builder/edit-builder.component';
import { ProfileComponent } from './views/profile/profile.component';
import {SortablejsModule} from "angular-sortablejs";
import {NgxGalleryModule} from "ngx-gallery";
import { MessageBoardComponent } from './views/project/message-board/message-board.component';
import { SettingComponent } from './views/setting/setting.component';
import { ResetpasswordComponent } from './common/resetpassword/resetpassword.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {ProjectMileStoneComponent} from "./views/project/milestone/milestone.component";


export function tokenGetter() {
  return sessionStorage.getItem('blank_one');
}


@NgModule({
  declarations: [
    AppComponent,
    ActivebarComponent,
    MenuComponent,
    LoginComponent,
    NoComponentFoundComponent,
    SplashComponent,
    SuburbsComponent,
    PropertiesBySuburbComponent,
    RunningProjectsComponent,
    ProjectCardComponent,
    ProjectDetailsComponent,
    TemplateComponent,
    TemplateListingComponent,
    TemplateEditComponent,
    ProjectMileStoneComponent,
    TaskComponent,
    TaskHeaderComponent,
    SignupComponent,
    AlertComponent,
    AddEditComponent,
    TemplateCategoryListComponent,
    AddTemplateCategoryComponent,
    EditTemplateCategoryComponent,
    AddTemplateComponent,
    AddMilestoneComponent,
    EditMilestoneComponent,
    EditTemplateComponent,
    AddEditPropertyComponent,
    EditPropertyComponent,
    EditSuburbComponent,
    PropertyCardComponent,
    CreateProjectComponent,
    ViewProjectComponent,
    AddMilestonesProjectComponent,
    DisableControlDirective,
    UserComponent,
    AddUserComponent,
    EditUserComponent,
    EditProjectComponent,
    BreadcrumbComponent,
    BuilderComponent,
    RegisterBuilderComponent,
    EditBuilderComponent,
    ProfileComponent,
    MessageBoardComponent,
    SettingComponent,
    ResetpasswordComponent,
    MilestoneComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ProjectRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    ToastrModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:4200'],
        blacklistedRoutes: []
      }
    }),
    SortablejsModule.forRoot({animation: 150}),
    NgxGalleryModule,
    DragDropModule,
    NgSelectModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})



export class AppModule { }
