import { Injectable } from '@angular/core';
import { ToastrManager} from 'ng6-toastr-notifications';
import {text} from "@angular/core/src/render3/instructions";


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  messages = '';

  constructor(public toaster: ToastrManager) {

  }

  success(message: any) {
    this.buildMessage(message);
    this.toaster.successToastr(this.messages, 'Success!');
  }


  error(message: any) {
    this.buildMessage(message);
    this.toaster.errorToastr(this.messages, 'Error');
  }

  warning(message: any) {
    this.buildMessage(message);
    this.toaster.warningToastr(this.messages, 'Error');
  }

  buildMessage(message) {
    this.messages = '';
    if (message instanceof Object) {
      if ('error' in message) {
          const errors = message.error;
          if (errors instanceof Array ) {
          errors.forEach(error => {
            this.messages += error + '\n';
          });
        } else {
          this.messages = message.error.message;
        }
      }
    } else {
      this.messages = message;
    }
  }


}
