import { Component, OnInit, Input, SimpleChange, Output } from '@angular/core';
import { AuthenticateService } from '../../services/login/authenticate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input('toggleMenuShow') toggleStatus = false;
  @Output('menuItem') selectedMenuItem: string;
  isOverlay: boolean;
  isVisible: boolean;
  loggedInUser: string;
  menuItems: {name: string, link: string, icon: string}[] = [

    {'name' : 'Suburbs', 'link' : 'suburbs' , 'icon' : 'folder open'},
    {'name': 'Projects', 'link' : 'runningprojects' , 'icon' : 'building'},
  ];
  constructor(private authservice: AuthenticateService,
              private router: Router) {
    const currentUserRole = sessionStorage.getItem('user-role');
    if (currentUserRole === 'builder') {
      this.menuItems.splice( 2, 0, {'name': 'Templates', 'link': 'templates', 'icon': 'configure'});
      this.menuItems.splice(3, 0,  {'name': 'Users', 'link': 'users', 'icon': 'user'});
      this.menuItems.splice(4, 0, {'name': 'Settings', 'link': 'config', 'icon': 'cogs'});
    }

    if (currentUserRole === 'admin') {
      this.menuItems = [
        {'name': 'Builders', 'link': 'builders', 'icon': 'user'},
      ];
    }
   }

  ngOnInit() {
   this.loggedInUser = sessionStorage.getItem('userName');
  }

  ngOnChanges(change: {[propKey: string]: SimpleChange}) {
        const currentValue: boolean = change.toggleStatus.currentValue;
        this.isOverlay = currentValue;
        this.isVisible = currentValue;
  }

  hideMenu() {
    this.isOverlay = false;
    this.isVisible = false;
  }

  toggle(selectItem: string) {
    this.selectedMenuItem = selectItem;

  }
  goToProfile() {
   this.hideMenu();
    this.router.navigate(['/profile']);
  }

  logout() {
    sessionStorage.setItem('isLoggedIn', 'false');
    sessionStorage.setItem('blank_one', '');
    this.authservice.setLoggedInUser(null);
    this.router.navigate(['/login']);
  }

  openPage(menuItem: string) {

  }

}
