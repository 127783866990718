import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AlertService} from "../../services/alert.service";
import {AuthenticateService} from "../../services/login/authenticate.service";

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  private token = null;
  private email = null;
  resetPasswordForm: FormGroup;
  constructor(private router: Router,
              private activeRoute: ActivatedRoute,
              private fb: FormBuilder,
              private alertService: AlertService,
              private authenticateService: AuthenticateService) {

    const routeParams = this.activeRoute.snapshot.params;
    const routeQueryParams = this.activeRoute.snapshot.queryParams;
    this.token = routeParams['token'];
    this.email = routeQueryParams['email'];
    this.resetPasswordForm = this.fb.group({
      password: '',
      password_confirmation: ''
    });
  }
  ngOnInit() {
  }

  resetPassword() {
    const resetPasswordData = this.resetPasswordForm.value;
    resetPasswordData.token = this.token;
    resetPasswordData.email = this.email;
    this.authenticateService.resetPassword(resetPasswordData)
      .subscribe(
        data => {
          console.log(data);
          this.alertService.success('Password reset successfully. Please login to continue');
          this.router.navigate(['login']);
        },
        error => {
          this.alertService.error(error);
        }
      );


  }



}
