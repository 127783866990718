import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Task } from '../../models/data-models';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit, OnChanges {
@Input("task") task:Task;
@Output("taskSelected") select=new EventEmitter<boolean>();
@Input("isTaskActive") isTaskActive:boolean =false;

_active:boolean=false;
  constructor() { }

  ngOnInit() {
    this.setTaskActive(this.isTaskActive);
    this.setRAGStatus();
    
  }

  ngOnChanges(){
    
   this.setTaskActive(this.isTaskActive);
    
  }

  toggleTaskActiveAction(){
    this.setTaskActive(!this._active);
    this.select.emit(true);
  }
  setTaskActive(isTaskActive){
    this._active=isTaskActive;
  }

  ragColor:string;
  setRAGStatus(){
    if(this.task.progress_performance>=90){
      this.ragColor="green";
    } 
    if(this.task.progress_performance<90){
      this.ragColor="yellow";
    }
    if(this.task.progress_performance<60){
      this.ragColor="red";
    }
  }
}
