import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { AlertService} from '../../../services/alert.service';
import { FormGroup, FormBuilder} from '@angular/forms';
import {ProjectService} from "../../../services/project.service";



@Component({
  selector: 'app-message-board',
  templateUrl: './message-board.component.html',
  styleUrls: ['./message-board.component.css']
})
export class MessageBoardComponent implements OnInit {

  messageBoardForm: FormGroup;
  private projectId: any;
  messageBoards: any;
  posting = false;

  constructor(private route: Router,
              private activeRoute: ActivatedRoute,
              private alertService: AlertService,
              private fb: FormBuilder,
              private projectService: ProjectService) {

    this.messageBoardForm = this.fb.group({
      projectId: '',
      message: '',
    });
  }

  ngOnInit() {
    this.setForm();
    this.getMessages();
  }

  getMessages() {
    this.projectService.getMessages(this.projectId)
      .subscribe(
        data => {
          this.messageBoards = data;
        },
        error => {
         this.alertService.error(error);
        }
      );
  }

  setForm() {
    const routeParams = this.activeRoute.snapshot.params
    this.projectId = routeParams['projectId'];
    this.messageBoardForm.patchValue({
      projectId: this.projectId
    });
  }

  addMessage() {
    this.posting = true;
    const message = this.messageBoardForm.value;
    const newMessage = {};
    newMessage['comment'] = message['message'];
    newMessage['self'] = true;
    newMessage['author'] = {name: 'You'};
    newMessage['created_at'] = new Date();
    if (message['message'] === '' || message['message'] === null ) {
      this.alertService.error('Message cannot be empty');
      return;
    }
    this.messageBoards.splice(this.messageBoards.length, 0, newMessage);
    this.messageBoardForm.reset();
    this.messageBoardForm.patchValue({
      projectId: this.projectId,
    });
    this.projectService.addMessage(message)
      .subscribe(
        data => {
          this.messageBoards[this.messageBoards.length - 1]['id'] = data['id'];
        }
        ,
        error => {
        this.messageBoards = this.messageBoards.filter(u => u !== newMessage);
        }
      );
  }

  deleteMessage(message) {
    this.messageBoards = this.messageBoards.filter(u => u !== message);
    this.projectService.removeMessage(message)
      .subscribe(
        data => {
          // do something
        },
        error => {
          // do something
        }
      );
  }

}
