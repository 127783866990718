import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '../../../../node_modules/@angular/router';
import { AuthenticateService } from '../../services/login/authenticate.service';
import { environment } from '../../../environments/environment.prod';
import {AlertService} from "../../services/alert.service";
import {NgxSmartModalService} from "ngx-smart-modal";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username = '';
  password = '';
  loginSuccess = true;
  loginSubmit = false;
  forgotPasswordModalId = 'forgotPassword';
  forgorPasswordForm: FormGroup;
  @Output() loginValidated = new EventEmitter<boolean>();

  versionnumber: string = environment.version_number;
  error = '';
  sendingMail = false;
  constructor(private router: Router,
              private authenticateService: AuthenticateService,
              private alertService: AlertService,
              private modalService: NgxSmartModalService,
              private fb: FormBuilder) {

    this.authenticateService.validateState(false);
    this.forgorPasswordForm = this.fb.group({
      email: ''
    });
  }

  ngOnInit() {
  }

  checkLogin() {
    this.authenticateService.checkLogin(this.username, this.password)
    .subscribe((isLoggedIn: boolean) => {
      if ( isLoggedIn ) {
          this.authenticateService.validateState(true);
          this.loginSuccess = true;
          this.loginValidated.emit(this.loginSuccess);
          this.loginSubmit = false;
          this.router.navigate(['/splash']);
      } else {
         // this.router.navigate(['/login']);
         this.error = 'Invalid login';
         this.alertService.error(this.error);
         this.loginSubmit = false;
        }
    },
      error => {
        this.alertService.error(error);
      });
  }

  openForgotPasswordModal() {
    this.modalService.getModal(this.forgotPasswordModalId).open();
  }

  sendMailWithLink() {
    this.sendingMail = true;
    const email = this.forgorPasswordForm.value;
    if (email['email'] === '' || email['email'] === null) {
      this.alertService.error('Email is required');
    }
    this.authenticateService.forgotPassword(email)
      .subscribe(
        data => {
          this.sendingMail = false;
          this.alertService.success('An email has been sent to your email address with the link');
          this.forgorPasswordForm.reset();
          this.modalService.getModal(this.forgotPasswordModalId).close();
        }, error => {
          this.sendingMail = false;
          this.alertService.error(error);
        }
      );
  }



  onSubmit() {
    this.loginSubmit = true;
    this.checkLogin();
  }
}
