import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router, ActivationEnd} from '@angular/router';
import {Breadcrumb} from '../../breadcrumb';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  ngOnInit(): void {
  }

  }


