import { Component, OnInit } from '@angular/core';
import { AddEditSuburbsService } from '../../../services/add-edit-suburbs.service';
import { Router } from '@angular/router';
import { AlertService} from '../../../services/alert.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.css']
})
export class AddEditComponent implements OnInit {
  name = '';
  adding = false;
  constructor(private addEditService: AddEditSuburbsService,
              private router: Router,
              private alertService: AlertService) { }

  ngOnInit() {

  }

  addSuburb() {
    this.adding = true;
    const suburb = {name: this.name};
    this.addEditService.addSuburb(suburb)
      .subscribe(
        data => {
          this.adding = false;
          this.alertService.success('New Suburb Added');
          this.router.navigate(['/suburbs']);

        },
        error => {
          this.adding = false;
          this.alertService.error(error);
        }
      );
  }


}


