import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GetProjectService } from '../../services/get-project.service';
import {first, switchMap} from 'rxjs/operators';
import { Project } from '../../models/data-models';
import { Observable, of } from 'rxjs';
import {NgxSmartModalService} from "ngx-smart-modal";
import {AlertService} from "../../services/alert.service";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-running-projects',
  templateUrl: './running-projects.component.html',
  styleUrls: ['./running-projects.component.css']
})
export class RunningProjectsComponent implements OnInit {

  projects: any;
  dimmer:boolean=false;
  error:string="";
  filteredProjects: any;
  showprojectList:boolean=true;
  deleteOption = false;
  editOption = false;
  selectedProjectToEdit: Project;
  selectedProjectToDelete: Project;
  deleteProjectModalId = 'deleteProject';
  deleting = false;
  userRole: any;

  constructor( private route:ActivatedRoute,
               private projectService:GetProjectService,
               private router:Router,
               private modalService: NgxSmartModalService,
               private alertService: AlertService,
               private navService: NavigationService)
  {
    const breadcrumbs = [
      {
        display: 'Projects',
        link: 'projects',
      },
    ];
    this.navService.setNavigation(breadcrumbs);
  }

  ngOnInit() {
    this.dimmer=true;
    this.route.paramMap
      .pipe(switchMap((params: ParamMap) => {
        return  of(params.get('id'));

      }))
      .subscribe((suburbID:string)=>{
        this.getProjects(suburbID);
      });

  }

  getProjects(suburbID) {

    this.projectService.getProjects(suburbID)
      .subscribe(data => {
          this.dimmer = false;
          this.userRole = data.headers.get('User-Role');
          this.projects = this.filteredProjects = data.body;
        },
        (error)=>{
          this.dimmer=false;
          this.error=error;
        }
      );
  }

  clickDirector(project: Project) {
    if (!this.deleteOption && ! this.editOption) {
      this.router.navigate(['/property', project.property.id, 'project', project.id ],
        {queryParams: {property: project.property.name, project: project.name}, queryParamsHandling: 'merge'});
    }
  }

  filterProjects (project) {
    if (!project) {
      this.assignCopy();
    }
    this.filteredProjects = Object.assign([], this.projects).filter(
      item => (item.name.toLowerCase().indexOf(project.toLowerCase()) > -1
              || item.property.name.toLowerCase().indexOf(project.toLowerCase()) > -1
              || item.property.suburb.name.toLowerCase().indexOf(project.toLowerCase()) > -1)
    );
  }

  assignCopy() {
    this.filteredProjects = Object.assign([], this.projects);
  }

  showEditOption(event) {
    event.stopPropagation();
    this.editOption = !this.editOption;
    this.deleteOption = false;
  }

  showDeleteOption(event) {
    event.stopPropagation();
    this.deleteOption = !this.deleteOption;
    this.editOption = false;
  }

  editProject(project) {
    this.projectService.setProject(project);
    this.router.navigate(['/project/edit', project.id]);
  }

  showConfirmDeleteOption(project) {
    this.selectedProjectToDelete = project;
    this.modalService.getModal(this.deleteProjectModalId).open();
  }

  removeProject(project) {
    this.deleting = true;
    if (project.status === 'active') {
      this.alertService.error('Cannot delete active project');
      this.deleting = false;
      this.closeModal(this.deleteProjectModalId);
      return ;
    }
    this.filteredProjects = this.projects.filter(u => u !== project);
    this.projectService.deleteProject(project)
      .subscribe(
        data => {
          this.alertService.success('Project deleted');
          this.deleting = false;
          this.closeModal(this.deleteProjectModalId);
        },
        error => {
          this.alertService.error(error);
          this.deleting = false;
          this.closeModal(this.deleteProjectModalId);
        }
      );
  }

  closeModal(id) {
    this.modalService.close(id);
  }
}
