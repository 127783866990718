import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ProjectService} from '../../../services/project.service';
import { AlertService} from '../../../services/alert.service';
import { Router, ActivatedRoute} from '@angular/router';
import { first} from 'rxjs/operators';
import {Milestones} from '../../../models/data-models';
import {TemplateService} from '../../../services/template.service';
import {FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import { NgxSmartModalService} from 'ngx-smart-modal';
import { JwtHelperService} from '@auth0/angular-jwt';
import {IMyDpOptions} from 'mydatepicker';
import {NavigationService} from '../../../services/navigation.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation} from 'ngx-gallery';
import Helpers from '../../../utils/helpers';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";


@Component({
  selector: 'app-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.css']
})
export class ViewProjectComponent implements OnInit {

  @ViewChild('milestoneName', {read: ElementRef}) milestoneName: ElementRef;

  dimmer = false;
  modalDimmer = false;
  projectId = null;
  propertyId = null;
  project: any = {
    status: ''
  };
  milestones: Milestones[];
  projectForm: FormGroup;
  taskModalForm: FormGroup;
  taskCommentForm: FormGroup;
  taskPictureForm: FormGroup;
  saving = false;
  deleteOption = false;
  editMode = false;
  editOption = false;
  disable = true;
  saveTasks = false;
  addPicture = false;
  addingComment = false;
  addingPicture = false;
  choosingTemplate = false;
  choosenTemplate = null;
  token_name = 'blank_one';
  taskPicture: File;
  taskPicDatas: any;
  taskCommentDatas: any;
  largePicture: any =  {
    image: ''
  };
  removingTaskPicture = false;
  pictureModalId = 'taskPicture';
  deletingMilestone = false;
  deletingTask = false;
  loadingTemplates = false;
  chooseTemplateButton = false;
  deleteMileStoneModalId = 'deleteMilestone';
  deleteTaskModalId = 'deleteTasks';
  chooseTemplatesModalId = 'chooseTemplate';
  lockProjectModalId = 'lockProject';
  milestoneIndex = null;
  taskIndex = null;
  taskControl = null;
  templates = null;
  selectedTaskProgress: any = {
    value : 0
  };
  userRole: any;
  currentUser: any;
  projectStatus: any;
  taskPictureIndex: any;


  public myDatePickerOptions: IMyDpOptions = {
    // other options...

    dateFormat: 'yyyy-mm-dd',
    disableUntil: {
      year: new Date().getFullYear(),
      month:  new Date().getMonth() + 1,
      day:  new Date().getDate()
    }
  };

  galleryOptions: NgxGalleryOptions[];
  constructor(private projectService: ProjectService,
              private alertService: AlertService,
              private templateService: TemplateService,
              private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private modalService: NgxSmartModalService,
              private jwtService: JwtHelperService,
              private navService: NavigationService,
  ) {

    const routeParams = this.route.snapshot.params;
    this.propertyId = routeParams.propertyId;
    this.projectId = routeParams.projectId;
    const queryParams = this.route.snapshot.queryParams;
    const breadcrumbs = [
      {
        display: queryParams['property'],
        link: 'property/details/' + this.propertyId ,
        queryParams: <any> {
          suburb : queryParams['suburb'],
          suburbId: queryParams['suburbId'],
          property: queryParams['property'],
        } ,
      },
      {
        display: queryParams['project'],
        link: '',
        queryParams: '',
      }
    ];

    if (queryParams.hasOwnProperty('suburb')) {
      breadcrumbs.splice(0, 0, { display: 'Suburbs', link: 'suburbs', queryParams: ''});
      breadcrumbs.splice(1, 0,
        { display: queryParams['suburb'],
          link: 'propertiesbysuburb/' + this.route.snapshot.queryParams['suburbId'],
          queryParams: {suburb: this.route.snapshot.queryParams['suburb']}});
    } else {
      breadcrumbs.splice(0, 0,
        { display: 'Projects',
          link: 'projects',
          queryParams: ''});
    }

    this.navService.setNavigation(breadcrumbs);
    this.taskModalForm = this.fb.group({
      milestoneId: '',
      tasks: this.fb.array([
        this.fb.group({
          taskName: ''
        })
      ])
    });
    this.taskCommentForm = this.fb.group({
      taskId: '',
      comments: this.fb.array([
        this.fb.group({
          comment: [null, Validators.required],
          author: '',
        })
      ])
    });

    this.taskPictureForm = this.fb.group({
      taskId: '',
      pictures: this.fb.array([
        this.fb.group({
          picture: ''
        })
      ])
    });
  }


  ngOnInit() {
    const decodedToken = this.jwtService.decodeToken(sessionStorage.getItem(this.token_name));
    this.currentUser = decodedToken['sub'];
    this.initializeForms();
    this.setMilestones();
    this.galleryOptions = [
      { 'imageDescription' : true },
      { 'imageArrows': false, 'imageSwipe': true,  },
      { 'image': false, 'thumbnailsRemainingCount': true, 'height': '100px' },
      { 'breakpoint': 500, 'width': '100%', 'thumbnailsColumns': 2 }
    ];
  }

  initializeForms() {
    this.projectForm = this.fb.group({
      projectId: '',
      milestones: this.fb.array([])
    });
  }

  setMilestones() {
    this.dimmer = true;
    const control = <FormArray>this.projectForm.controls.milestones;
    this.projectService.getProjectDetails(this.projectId)
      .subscribe(
        data => {
          this.project = data['body'];
          this.projectForm.patchValue({projectId: data['body']['id']});
          this.milestones = data['body']['milestones'];
          this.milestones.sort((a, b) => a['order'] - b['order']);
          this.userRole = data.headers.get('User-Role');
          this.milestones.forEach(milestone => {
            milestone['totalTaskComplete'] = '';
            milestone['performanceColor'] = this.calculateColor(milestone['performance']);
            milestone['disable'] = true;
            control.push(this.fb.group({
              milestoneName: this.fb.control({value: milestone['name'], disabled: this.disable}),
              milestoneId: milestone['id'],
              tasks: this.setTasks(milestone, this.userRole)
            }));
          });
          this.dimmer = false;
        },
        error => {
          this.dimmer = false;
        }
      );
  }
  calculateColor(performance): string {

    if ( performance <= 0.20 ) {
      return 'ui circular empty label red';
    } else if ( performance > 0.20 && performance <= 0.40) {
      return 'ui circular empty label orange';
    } else if ( performance > 0.40 && performance <= 0.60) {
      return 'ui circular empty label yellow';
    } else if ( performance > 0.60 && performance <= 0.80) {
      return 'ui circular empty label olive';
    } else {
      return 'ui circular empty label green';
    }
  }

  setTasks(milestone, userRole) {

    const taskArr = new FormArray([]);
    let totalTasksCompleted = 0;
    milestone['gallery'] = [];
    milestone.tasks.forEach(task => {

      if (task['percent_complete'] == 100) {
        totalTasksCompleted += 1;
      }
      let taskDueDate: any = '';
      task['disable'] = true;
      if (task['due_date']) {
        const taskDueDateFromDB = new Date(task['due_date']);
        taskDueDate = {
          date: {
            year: taskDueDateFromDB.getFullYear(),
            month: taskDueDateFromDB.getMonth() + 1,
            day: taskDueDateFromDB.getDate()
          }
        };
        task['due_date'] = taskDueDate;
        task['userRole'] = userRole;
      }

      if (task['images'].length > 0) {
        milestone['gallery'].push(task['images']);
      }

      taskArr.push(this.fb.group({
        taskName: this.fb.control({ value: task['name'], disabled: this.disable}),
        taskId: task['id'],
        onTrack: this.fb.control({ value:  task['on_track'] == 1, disabled: this.disable}),
        ahead: this.fb.control({ value:  task['ahead'] == 1, disabled: this.disable}),
        behind: this.fb.control({ value:  task['behind'] == 1, disabled: this.disable}),
        percentComplete:  this.fb.control({ value: task['percent_complete'], disabled: this.disable}),
        dueDate: this.fb.control({ value: taskDueDate , disabled: this.disable}),
        userRole: this.fb.control( { value: userRole, disabled: this.disable})
      }));
    });
    milestone['gallery'] = Helpers.flatten(milestone['gallery']);
    if (milestone['gallery'].length > 0) {
      milestone['gallery'] = milestone['gallery'].filter(item => item !== null);
    }
    milestone['totalTaskComplete'] = milestone.tasks.length ? totalTasksCompleted + '/' + milestone.tasks.length : 'N/A';
    return taskArr;
  }


  get milestoneData() {
    return this.projectForm.get('milestones') as FormArray;
  }

  get taskModalData() {
    return this.taskModalForm.get('tasks') as FormArray;
  }

  get taskModalComments() {
    return this.taskCommentForm.get('comments') as FormArray;
  }

  get taskPicturesData() {
    return this.taskPictureForm.get('pictures') as FormArray;
  }

  enterMilestone(i) {
    // do something
  }

  milestoneDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.milestoneData.controls,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(
      this.milestoneData.value,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(
      this.milestones,
      event.previousIndex,
      event.currentIndex
    );

    this.projectService.sortMilestones(this.milestones)
      .subscribe(
        data => {
          // do something
        },
        error => {
          // do something
        }
      );

  }

  confirmLockProject(value) {
    this.projectStatus = value;
    this.modalService.getModal(this.lockProjectModalId).open();
  }

  lockTheProject(project) {this.saving = true;
    this.projectService.lockProject(project)
      .subscribe(
        data => {
          this.saving = false;
          this.closeModal(this.lockProjectModalId);
          this.initializeForms();
          this.setMilestones();
        },
        error => {
          this.saving = false;
          this.alertService.error(error);
        }
      );
  }
  addProjectDetails() {
    this.router.navigate(['/property', this.propertyId, 'project', this.projectId, 'add']);
  }

  editProjectDetails() {
    const milestones = this.milestoneData;
    for (let i = 0; i < milestones.controls.length; i++) {
      const milestoneControls = milestones.controls[i];
      milestoneControls['controls']['milestoneName']['disable']();
      this.milestones[i]['showCheckIcon'] = false;
      this.milestones[i]['showPlusIcon'] = false;
      for (let j = 0; j < milestoneControls['controls']['tasks'].controls.length; j++) {
        this.milestones[i]['tasks'][j]['showCheckButton'] = false;
        this.milestones[i]['tasks'][j]['disable'] = true;
        const taskControls = milestoneControls['controls']['tasks'].controls[j];
        taskControls['controls']['taskName']['disable']();
        taskControls['controls']['onTrack']['disable']();
        taskControls['controls']['ahead']['disable']();
        taskControls['controls']['behind'][ 'disable']();
        taskControls['controls']['percentComplete']['disable']();
        taskControls['controls']['dueDate']['disable']();

      }
    }
    // this.disable = !this.disable;
  }

  navigateToTemplates() {
    this.templateService.setChooseTemplateFromProject(this.projectId);
    this.router.navigate(['/templates']);
  }

  openChooseTemplatesModal() {
    this.loadingTemplates = true;
    this.modalService.getModal(this.chooseTemplatesModalId).open();
    this.templateService.getTemplates().subscribe(
      data => {
        this.templates = data['body'];
        this.loadingTemplates = false;
      },
      error => {
        this.loadingTemplates = false;
        this.alertService.error(error);
      }
    );
  }

  chooseTemplateForProject() {
    this.choosingTemplate = true;
    this.templateService.copyTemplateMilestoneAndTasks(this.choosenTemplate, this.projectId)
      .subscribe(
        data => {
          this.chooseTemplateButton = false;
          this.choosingTemplate = false;
          this.closeModal(this.chooseTemplatesModalId);
          this.initializeForms();
          this.setMilestones();
        },
        error => {
          this.chooseTemplateButton = false;
          this.choosingTemplate = false;
          this.closeModal(this.chooseTemplatesModalId);
          this.alertService.error(error);
        }
      );
  }

  thisTemplateChoosen(element) {
    this.chooseTemplateButton = true;
    this.choosenTemplate = element.nextSibling.value;
  }


  saveProjectDetails() {
    this.saving = true;
    const projectDetails = this.projectForm.getRawValue();
    projectDetails.projectId = this.projectId;
    this.projectService.addProjectDetails(projectDetails)
      .subscribe(
        data => {
          this.saving = false;
          this.alertService.success('Details updated');
          this.initializeForms();
          this.setMilestones();
        },
        error => {
          this.saving = false;
          this.alertService.error(error);
        }
      );
  }

  openMilestone(index) {
    const milestone = this.milestones[index];
    milestone['collapse'] = !milestone['collapse'];
    if (this.userRole === 'customer') {
      milestone['galleryImages'] = milestone['gallery'].map(function(picture) {
        const imgObj = {
          small: picture['image'],
          meduim: picture['image'],
          big: picture['image']
        } ;
        return imgObj;
      });
    }
  }

  showEditOption (event) {
    event.stopPropagation();
    this.deleteOption = false;
    this.editOption = !this.editOption;
    this.editProjectDetails();
  }

  editMilestone(index) {
    const milestoneDisable =  this.milestones[index]['disable'];
    this.milestones[index]['disable'] = !milestoneDisable;
    const control = <FormArray>this.projectForm.controls.milestones;
    const milesStoneControl = control['controls'][index]['controls'];
    milesStoneControl['milestoneName'][milestoneDisable ? 'enable' : 'disable']();
    this.milestones[index]['showPlusIcon'] = !this.milestones[index]['showPlusIcon'];

  }

  saveMileStone(index) {
    const control = <FormArray>this.projectForm.controls.milestones;
    const milesStoneControl = control['controls'][index]['controls'];
    const milestone = {
      name: milesStoneControl['milestoneName']['value'],
      id: milesStoneControl['milestoneId']['value'],
      projectId: this.projectId};
    this.milestones[index]['showCheckIcon'] = false;
    this.projectService.saveMilestone(milestone)
      .subscribe(
        data => {
          milesStoneControl['milestoneName']['value'] = data['name'];
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  editMiletoneTask(control, milestoneIndex, taskindex) {

    const taskDisable = this.milestones[milestoneIndex]['tasks'][taskindex]['disable'];
    const taskControl = control['controls'][taskindex]['controls'];
    const taskProgress = taskControl['percentComplete']['value'] < 100;
    let dateSet = false;
    let isBuilder = false;
    if (taskControl['userRole']['value'] === 'builder') {
      isBuilder = true;
      dateSet = true;
    } else if (taskControl['dueDate']['value'] == ''
      && taskControl['userRole']['value'] !== 'customer'  ) {
      dateSet = true;
    }

    taskControl['taskName'][taskDisable && isBuilder ? 'enable'  : taskDisable && taskProgress ? 'enable' : 'disable']();
    taskControl['onTrack'][taskDisable && isBuilder ? 'enable'  : taskDisable && taskProgress ? 'enable' : 'disable']();
    taskControl['ahead'][taskDisable && isBuilder ? 'enable'  : taskDisable && taskProgress ? 'enable' : 'disable']();
    taskControl['behind'][taskDisable && isBuilder ? 'enable'  : taskDisable && taskProgress ? 'enable' : 'disable']();
    taskControl['percentComplete'][taskDisable && isBuilder ? 'enable'  : taskDisable && taskProgress ? 'enable' : 'disable']();
    taskControl['dueDate'][taskDisable && isBuilder ? 'enable' :  taskDisable && taskProgress && dateSet ? 'enable' : 'disable']();
    this.milestones[milestoneIndex]['tasks'][taskindex]['showCheckButton']
      = !this.milestones[milestoneIndex]['tasks'][taskindex]['showCheckButton'];
    this.milestones[milestoneIndex]['tasks'][taskindex]['disable'] = !taskDisable;

  }

  saveTask(control, milestoneIndex, taskIndex) {
    this.milestones[milestoneIndex]['tasks'][taskIndex]['saving'] = true;
    const taskControl = control['controls'][taskIndex]['controls'];
    const task = {
      taskId : taskControl['taskId']['value'],
      taskName : taskControl['taskName']['value'],
      onTrack: taskControl['onTrack']['value'],
      ahead: taskControl['ahead']['value'],
      behind: taskControl['behind']['value'],
      percentComplete: taskControl['percentComplete']['value'],
      dueDate: taskControl['dueDate']['value'],
      projectId: this.projectId
    };
    this.projectService.saveMilestoneTask(task)
      .subscribe(
        data => {
          let taskDueDate: any = '';
          if (data['due_date']) {
            const taskDueDateFromDB = new Date(data['due_date']);
            taskDueDate = {
              date: {
                year: taskDueDateFromDB.getFullYear(),
                month: taskDueDateFromDB.getMonth() + 1,
                day: taskDueDateFromDB.getDate()
              }
            };
          }
          taskControl['taskName']['value'] = data['name'] ;
          taskControl['onTrack']['value'] = data['on_track'] ;
          taskControl['ahead']['value'] = data['ahead'];
          taskControl['behind']['value'] = data['behind'] ;
          taskControl['percentComplete']['value'] = data['percent_complete'] ;
          taskControl['dueDate']['value'] = taskDueDate;
          this.editMiletoneTask(control, milestoneIndex, taskIndex);
          this.updateMilestone( this.milestones, milestoneIndex);
          this.milestones[milestoneIndex]['tasks'][taskIndex]['saving'] = false;
        },
        error => {
          this.editMiletoneTask(control, milestoneIndex, taskIndex);
          this.milestones[milestoneIndex]['tasks'][taskIndex]['saving'] = false;
          this.alertService.error(error);
        }
      );
  }

  updateMilestone(milestone, i ) {
    this.projectService.updatePerformance(milestone[i]['id'])
      .subscribe(
        data => {
          this.milestones[i]['performanceColor'] = this.calculateColor(data['performance']);
          this.milestones[i]['complete'] = data['complete'];
          this.milestones[i]['totalTaskComplete'] = data['totalTaskComplete'];
          this.milestones[i]['maximum_due_date'] = data['maximum_due_date'];
          let milestoneComplete = 0;
          this.milestones.forEach( updatedMilestone => {
            milestoneComplete += updatedMilestone['complete'];
          });
          this.project['canLock'] = milestoneComplete / this.milestones.length == 1;
        },
        error => {
          // do something
        }
      );
  }



  showDeleteOption(event) {
    event.stopPropagation();
    this.editOption = false;
    this.deleteOption = !this.deleteOption;
    this.editProjectDetails();
  }
  confirmDeleteMilestone(index) {
    this.milestoneIndex = index;
    this.modalService.getModal(this.deleteMileStoneModalId).open();
  }




  deleteMilestoneWithTasks(index) {
    this.deletingMilestone = true;
    const control = <FormArray>this.projectForm.controls.milestones;
    const milestone = control.value;
    control.removeAt(index);
    this.milestones = this.milestones.filter(u => u !== this.milestones[index]);
    this.projectService.deleteMilestone(milestone[index])
      .subscribe(
        data => {
          this.deletingMilestone = false;
          this.alertService.success('Successfully deleted');
          this.closeModal(this.deleteMileStoneModalId);
        },
        error => {
          this.deletingMilestone = false;
          this.alertService.error(error);
          this.closeModal(this.deleteMileStoneModalId);
        }
      );
  }

  confirmDeleteTask(control, index) {
    this.taskIndex = index;
    this.taskControl = control;
    this.modalService.getModal(this.deleteTaskModalId).open();
  }

  deleteTask(control, index) {
    this.deletingTask = true;
    const task = control.value;
    control.removeAt(index);
    this.projectService.deleteTask(task[index])
      .subscribe(
        data => {
          this.deletingTask = false;
          this.alertService.success('Successfully deleted');
          this.closeModal(this.deleteTaskModalId);
        },
        error => {
          this.deletingTask = false;
          this.alertService.error(error);
          this.closeModal(this.deleteTaskModalId);
        }
      );
  }

  openMilestoneTaskModal(id, milestone) {
    this.clearAddTaskModal();
    this.taskModalForm.patchValue({milestoneId: milestone['value']['milestoneId']});
    this.modalService.getModal(id).open();
  }

  addMilestoneTasks(modal) {
    this.saveTasks = true;
    const milestoneTasks = this.taskModalForm.value;
    if (milestoneTasks['tasks'].length === 1) {
      if ( milestoneTasks['tasks'][0]['taskName'] === '' || milestoneTasks['tasks'][0]['taskName'] === null) {
        this.alertService.error('At least one task is needed ');
        this.saveTasks = false;
        return;
      }
    }
    milestoneTasks['projectId'] = this.projectId;
    this.projectService.addMilestoneTasks(milestoneTasks)
      .subscribe(
        data => {
          this.saveTasks = false;
          this.closeTaskModalForm(modal);
          this.initializeForms();
          this.setMilestones();
        },
        error => {
          this.saveTasks = false;
          this.alertService.error(error);
        }
      );
  }

  openAddCommentModal(id, task, milestoneIndex, taskIndex) {
    this.taskCommentDatas = null;
    this.modalDimmer = true;
    const taskId = task['value']['taskId'];
    this.selectedTaskProgress = task['controls']['percentComplete'];
    this.taskCommentForm.reset();
    this.taskCommentForm.patchValue({taskId: taskId});
    this.modalService.getModal(id).open();
    this.milestoneIndex = milestoneIndex;
    this.taskIndex = taskIndex;
    this.projectService.getTaskComments(taskId)
      .subscribe(
        data => {
          this.taskCommentDatas = data;
          this.taskCommentDatas.forEach(comment => {
            if (this.currentUser === comment['author']['id']) {
              comment['canRemove'] = true;
            }
          });
          this.modalDimmer = false;
        },
        error => {
          this.modalDimmer = false;
        }
      );
  }

  addComments() {

    const commentData = this.taskCommentForm.value;
    if (commentData['comments'][0]['comment'] === '' || commentData['comments'][0]['comment'] === null ) {
      this.alertService.error('Comment cannot be empty');
      return;
    }
    const newComment = [];
    commentData['comments'][commentData['comments'].length - 1]['author'] = this.currentUser;
    newComment['author'] = {name: 'You'};
    newComment['canRemove'] = true;
    newComment['comments'] = commentData['comments'][0]['comment'];
    newComment['created_at'] = new Date();
    this.taskCommentForm.reset();
    this.taskCommentForm.patchValue({
      taskId: commentData['taskId']
    });
    this.taskCommentDatas.splice(this.taskCommentDatas.length, 0, newComment);
    this.milestones[this.milestoneIndex]['tasks'][this.taskIndex]['comments_count']
      = parseFloat(this.milestones[this.milestoneIndex]['tasks'][this.taskIndex]['comments_count']) + 1 ;
    this.milestones[this.milestoneIndex]['totalComments'] = parseFloat(this.milestones[this.milestoneIndex]['totalComments']) + 1 ;
    this.projectService.addComments(commentData)
      .subscribe(
        data => {
          this.taskCommentDatas[this.taskCommentDatas.length - 1]['id'] = data['id'];
        },
        error => {
          this.taskCommentDatas = this.taskCommentDatas.filter(u => u !== newComment)
          this.milestones[this.milestoneIndex]['tasks'][this.taskIndex]['comments_count']
            -= 1 ;
        }
      );
  }

  openTaskPictures(id, task, milestoneIndex, taskIndex) {
    this.taskPicDatas = null;
    this.modalDimmer = true;
    const taskId = task['value']['taskId'];
    this.selectedTaskProgress = task['controls']['percentComplete'];
    this.taskPictureForm.reset();
    this.taskPictureForm.patchValue({taskId: taskId});
    this.modalService.getModal(id).open();
    this.milestoneIndex = milestoneIndex;
    this.taskIndex = taskIndex;
    this.taskPicDatas = this.milestones[milestoneIndex]['tasks'][taskIndex]['images'];
    this.taskPicDatas.forEach(picture => {
      const author  = picture['author'] ? picture['author']['id'] : null;
      if (this.currentUser === author) {
        picture['canRemove'] = true;
      }
      picture['small'] = picture['image'];
      picture['meduim'] = picture['image'];
      picture['big'] = picture['image'];
    });
    this.modalDimmer = false;
  }

  addTaskPicture() {
    this.addingPicture = true;
    const pictureFormData = new FormData();
    const pictureData = this.taskPictureForm.value;
    pictureFormData.append('picture', this.taskPicture);
    pictureFormData.append('taskId', pictureData['taskId']);
    pictureFormData.append('author', this.currentUser);
    this.projectService.addPictures(pictureFormData)
      .subscribe(
        data => {
          const addedPicture = JSON.parse(data);
          addedPicture['canRemove'] = true;
          this.taskPicDatas.push(addedPicture);
          this.milestones[this.milestoneIndex]['tasks'][this.taskIndex]['pictures_count']
            = parseFloat(this.milestones[this.milestoneIndex]['tasks'][this.taskIndex]['pictures_count']) + 1;
          this.milestones[this.milestoneIndex]['totalPictures']
            = parseFloat(this.milestones[this.milestoneIndex]['totalPictures']) + 1 ;
          this.addingPicture = false;
        },
        error => {
          this.addingPicture = false;

        }
      );
  }

  makePicturePublic(picture) {
    this.projectService.makePicturePublic(picture)
      .subscribe(
        data => {
          this.largePicture = data;
          this.largePicture['canRemove'] = true;
          this.taskPicDatas[this.taskPictureIndex] = data;
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  addTaskModalInput() {
    this.taskModalData.push(this.fb.group({taskName: ''}));
  }
  closeTaskModalForm(modal) {
    this.taskModalForm.reset();
    modal.close();
  }

  closeCommentForm(modal) {
    this.taskCommentForm.reset();
    modal.close();
  }

  closePictureModal(modal) {
    this.taskPictureForm.reset();
    modal.close();
  }

  clearAddTaskModal() {
    while (this.taskModalForm.get('tasks')['controls'].length !== 1) {
      this.taskModalData.removeAt(0);
    }
  }

  removeTaskModalInput(index) {
    this.taskModalData.removeAt(index);
  }

  onTaskPictureSelected(event) {
    this.taskPicture = <File> event.target.files[0];
  }



  removeTaskComment(comment) {
    this.taskCommentDatas = this.taskCommentDatas.filter(u => u !== comment);
    this.projectService.deleteTaskComment(comment)
      .subscribe(
        data => {
          this.milestones[this.milestoneIndex]['tasks'][this.taskIndex]['comments_count']
            -= 1 ;
          this.milestones[this.milestoneIndex]['totalComments'] -= 1;
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  removeTaskPicture(picture) {
    this.removingTaskPicture = true;
    this.taskPicDatas = this.taskPicDatas.filter(u => u !== picture);
    this.projectService.deleteTaskPicture(picture)
      .subscribe(
        data => {
          this.milestones[this.milestoneIndex]['tasks'][this.taskIndex]['pictures_count']
            -= 1;
          this.milestones[this.milestoneIndex]['totalPictures'] -= 1;
          this.milestones[this.milestoneIndex]['tasks'][this.taskIndex]['images']
            = this.milestones[this.milestoneIndex]['tasks'][this.taskIndex]['images']
            .filter(u => u.id !== picture.id );
          this.alertService.success('Successfully deleted');
          this.removingTaskPicture = false;
          this.closeLargePicture();
        },
        error => {
          this.removingTaskPicture = false;
          this.alertService.error(error);
        }
      );
  }

  zoomPicture(picture, index) {
    this.largePicture = picture;
    this.taskPictureIndex = index;
    const modal = document.getElementById(this.pictureModalId);
    modal.style.display = 'block';
  }

  closeLargePicture() {
    this.largePicture = {image: ''};
    const modal = document.getElementById(this.pictureModalId);
    modal.style.display = 'none';
  }

  closeModal(id) {
    this.modalService.getModal(id).close();
  }

  toggleStatus(control, controlName) {
    if (controlName === 'onTrack') {
      control['ahead'].setValue(false);
      control['behind'].setValue(false);
    } else if (controlName === 'ahead') {
      control['onTrack'].setValue(false);
      control['behind'].setValue(false);
    } else {
      control['ahead'].setValue(false);
      control['onTrack'].setValue(false);
    }
  }

  cancelForm() {
    this.editMode = false;
    this.initializeForms();
    this.setMilestones();
  }




}
