import { Component, OnInit } from '@angular/core';
import {AlertService} from "../../services/alert.service";
import {NgxSmartModalService} from "ngx-smart-modal";
import {UserService} from "../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.css']
})
export class BuilderComponent implements OnInit {

  loading = false;
  users: any;
  filteredUsers: any;
  editOption =  false;
  deleteOption = false;
  selectedUserToEdit = false;
  selectedUserToDelete: any;
  deleteUserConfirmModalId = 'deleteBuilder';

  constructor(
    private alertService: AlertService,
    private modalService: NgxSmartModalService,
    private userService: UserService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private navService: NavigationService
  ) {
    const breadcrumbs = [
      {
        display: 'Builders',
        link: 'builders'
      },
    ];
    this.navService.setNavigation(breadcrumbs);
  }

  ngOnInit() {
    this.loading = true;
    this.getBuilders();
    this.assignCopy();

  }

  getBuilders() {
    this.userService.getBuilders()
      .subscribe(
        data => {
          this.users = this.filteredUsers = data.body;
          this.loading = false;
        }, error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  filterUsers(user) {
    if (!user) {
      this.assignCopy();
    }
    this.filteredUsers = Object.assign([], this.users).filter(
      item => item.name.toLowerCase().indexOf(user.toLowerCase()) > -1
    );
  }
  assignCopy() {
    this.filteredUsers = Object.assign([], this.users);
  }

  addUser() {
    this.router.navigate(['/builders/add']);
  }
  showEditOption(event) {
    event.stopPropagation();
    this.editOption = !this.editOption;
    this.deleteOption = false;
  }
  showDeleteOption(event) {
    event.stopPropagation();
    this.deleteOption = !this.deleteOption;
    this.editOption = false;
  }
  editUser(user) {
    this.userService.setUsertoEdit(user);
    this.router.navigate(['/builders/edit/', user.id]);
  }

  showConfirmDeleteOption(user) {
    this.selectedUserToDelete = user;
    this.modalService.getModal(this.deleteUserConfirmModalId).open();
  }

  removeUser(user) {
    this.loading = true;
    this.filteredUsers = this.users.filter(u => u !== user);
    this.userService.deleteUser(user)
      .subscribe(
        data => {
          this.alertService.success('User deleted');
          this.loading = false;
          this.closeModal(this.deleteUserConfirmModalId);
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
          this.closeModal(this.deleteUserConfirmModalId);
        }
      );
  }

  closeModal(id) {
    this.modalService.getModal(id).close();
  }

}
