import { Component, OnInit } from '@angular/core';
import { AlertService} from "../../../services/alert.service";
import { Router, ActivatedRoute} from "@angular/router";
import { ProjectService} from "../../../services/project.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {first} from "rxjs/operators";
import {IMyDpOptions} from 'mydatepicker';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnInit {

  createProjectForm: FormGroup;
  isSubmitted = false;
  propertyId = null;
  propertyAddress = null;

  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'yyyy-mm-dd',
  };

  constructor(private projectService: ProjectService,
              private alertService: AlertService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder ) {

    const routeParams = this.route.snapshot.params;
    this.propertyId = routeParams.propertyId;
    const queryParams = this.route.snapshot.queryParams;
    this.propertyAddress = queryParams['address'];

    this.createProjectForm = this.formBuilder.group({
      project_name: [null, Validators.required],
      property_address: [null],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.setData();
  }

  onSubmit() {
    this.isSubmitted = true;
    const project  = this.createProjectForm.value;
    project.property_id = this.propertyId;
    this.projectService.createProject(project)
      .subscribe(
        data => {
          this.alertService.success('Project Created');
          this.isSubmitted = false;
          this.router.navigate(['/property', 'details', this.propertyId]);
        },
        error => {
          this.alertService.error(error);
          this.isSubmitted = false;
        }
      );
  }

  setData() {
    this.createProjectForm.patchValue({
      property_address : this.propertyAddress
    });
  }

  setDate(): void {
    // Set today date using the patchValue function
    const date = new Date();
    this.createProjectForm.patchValue({start_date: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      },
      end_date: {
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
    });
  }

}
