import { Injectable } from '@angular/core';
import { BaseHttpService } from './http/base/base-http.service';
import { Observable, forkJoin } from 'rxjs';
import { Milestones, Task } from '../models/data-models';
import { Http } from '@angular/http';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { AuthenticateService } from './login/authenticate.service';
import { map } from 'rxjs/operators';
import he from "ng2-semantic-ui/locales/he";
import {headersToString} from "selenium-webdriver/http";

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseHttpService {

  constructor(protected http:HttpClient,protected authsvc:AuthenticateService) {
    super();
  }

  getProjectDetails(projectId: string) {
    return this.getMilestonesWithTasks(projectId);
  }

  getMilestonesWithTasks(projectId):  Observable<HttpResponse<Object>> {
    this.setResource('projects/' + projectId);
    return this.http.get<HttpResponse<Object>>(this.finalurl, { observe: 'response'});
  }
  addProjectDetails(projectDetails) {
    this.setResource('projects/' + projectDetails.projectId + '/saveDetails');
    return this.http.post(this.finalurl, projectDetails);
  }

  getMilestones(projectId:string): Observable<Milestones[]> {
    this.setResource('projects');
    return this.http.get<Milestones[]>(this.finalurl)
    .pipe(
      map((x: any[]) => {

        return x.map((item)=>{
              let f=new Milestones(item.id,item.name);
              return Object.assign(f,
                      {"id":item.id,"name":item.name, 
                       "start_date":item.start_date, "end_date":item.end_date,
                       "roll_up_progress_performance":item.progress
                      }
              )
            }
        )
      })
    );
  }

  getTasks(projectId:string):Observable<Task[]>{
    this.setResource("tasks") ; 
    return this.http.get<Task[]>(this.finalurl,this.authsvc.getJWTHeader())
    .pipe(
      map((x:any[])=>{
      
        return x.map((item:Task)=>{
              
              let f=new Task(item.id,item.name);
              return Object.assign(f,
                      { 
                          "id":item.id,
                          "name":item.name,
                          "milestoneId":item.milestoneId,
                          "projectId":item.projectId,
                          "progress_performance":item.progress,
                      }
              )
            }
        )
      })
    );
  }

  associateTasksToMilestones(milestones:Milestones[], tasks:Task[]){
      for(var i=0;i<milestones.length;i++){
        let tempTasks:Task[]=tasks.filter((individualTask:Task)=>{
              if(i==0){
              }
              return individualTask.milestoneId==milestones[i].id}
            )
        milestones[i].tasklist=tempTasks;
      }

      return milestones;
  }

  getMessages(projectId) {
    this.setResource('message-board/' + projectId );
    return this.http.get(this.finalurl);
  }
  addMessage(message) {
    this.setResource('message-board');
    return this.http.post(this.finalurl, message);
  }

  removeMessage(message) {
    this.setResource('message-board/' + message['id']);
    return this.http.delete(this.finalurl);
  }

  saveMilestone(milestone) {
    this.setResource('project-milestones/' + milestone['id'] );
    return this.http.put(this.finalurl, milestone);
  }

  saveMilestoneTask(task) {
    this.setResource('project-milestone-tasks/' + task['taskId'] );
    return this.http.put(this.finalurl, task);
  }

  updatePerformance(milestoneId) {
    this.setResource('project-milestones/performance');
    return this.http.post(this.finalurl, {milestoneId: milestoneId});
  }

  createProject(project) {
    this.setResource('projects');
    return this.http.post(this.finalurl, project);
  }

  addMilestone(milestone) {
    this.setResource('project-milestones');
    return this.http.post(this.finalurl, milestone);
  }

  deleteMilestone(milestone) {
    this.setResource('project-milestones/' + milestone.milestoneId);
    return this.http.delete(this.finalurl);
  }

  deleteTask(task) {
    this.setResource('project-milestone-tasks/' + task.taskId);
    return this.http.delete(this.finalurl);
  }
  addMilestoneTasks(milestoneTasks) {
    this.setResource('project-milestone-tasks');
    return this.http.post(this.finalurl, milestoneTasks);
  }
  addComments(commentData) {
    this.setResource('project-task-comments');
    return this.http.post(this.finalurl, commentData);
  }
  addPictures(pictureData) {
    this.setResource('project-task-pictures');
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    return this.http.post(this.finalurl, pictureData, {
      headers: headers,
      reportProgress: true,
      responseType: 'text'
    });
  }

  makePicturePublic(picture) {
    this.setResource('project-task-pictures/' + picture['id']);
    return this.http.put(this.finalurl, picture);
  }

  getTaskPictures(taskId) {
    this.setResource('project-task-pictures/' + taskId);
    return this.http.get(this.finalurl);

  }

  getMilestoneImages(milestone) {
    this.setResource('project-milestones/pictures/' + milestone['id'] );
    return this.http.get(this.finalurl);
  }

  getTaskComments(taskId) {
    this.setResource('project-task-comments/' + taskId);
    return this.http.get(this.finalurl);
  }

  deleteTaskComment(comment) {
    this.setResource('project-task-comments/' + comment['id']);
    return this.http.delete(this.finalurl);
  }

  deleteTaskPicture(picture) {
    this.setResource('project-task-pictures/' + picture['id']);
    return this.http.delete(this.finalurl);
  }

  lockProject(project) {
    this.setResource('projects/' + project['id']);
    project['lockUnlock'] = true;
    return this.http.put(this.finalurl, project);
  }

  sortMilestones(milestones) {
    this.setResource('project-milestones/sortMilestones');
    return this.http.post(this.finalurl, milestones);
  }

}
