import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {BaseHttpService} from "./http/base/base-http.service";

@Injectable({
  providedIn: 'root'
})
export class MilestoneService extends BaseHttpService {

  constructor(private http: HttpClient) {
    super();
  }

  addMilestone(milestone) {
    this.setResource('template-milestones');
    return this.http.post(this.finalurl, milestone);
  }
}


