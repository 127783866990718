import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { BaseHttpService } from './http/base/base-http.service';
import { PropertyList } from '../models/data-models';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GetPropertiesService extends BaseHttpService {

  constructor(private http: HttpClient) {
    super();
   }

   getProperties(suburbID): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.baseurl + "/properties/?suburbID="+suburbID, { observe: 'response'});
  }
  getPropertiesBySuburbId(suburbID): Observable<HttpResponse<Object>> {
    this.setResource('suburbs/' + suburbID )
    return this.http.get<HttpResponse<Object>>(this.finalurl, {observe: 'response'});
  }


  search(searchString:string):Observable<PropertyList[]> {
    return this.http.get<PropertyList[]>(this.baseurl + "/properties?name="+searchString);
  }
  addProperty(property) {

    this.setResource('properties');
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    return this.http.post(this.finalurl, property, {
      headers: headers,
      reportProgress: true,
      responseType: 'text'
    });
  }

  editProperty(property) {
    this.setResource('properties/' + property.id);
    return this.http.put(this.finalurl, property);
  }
  getProperty(property): Observable<HttpResponse<Object>> {
    this.setResource('properties/' + property);
    return this.http.get<HttpResponse<Object>>(this.finalurl, { observe: 'response'});
  }
  removeProperty(property) {
    this.setResource('properties/' + property);
    return this.http.delete(this.finalurl);
  }
  changeImage(propertyImage, id) {
    this.setResource('properties/changeImage/' + id);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    return this.http.post(this.finalurl, propertyImage, {
      headers: headers,
      reportProgress: true,
      responseType: 'text'
    });
  }


}
