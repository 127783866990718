import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import { Router} from '@angular/router';
import { RegisterService} from '../../services/login/register.service';
import { AlertService } from '../../services/alert.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  errors = '';
  name = '';
  email = '';
  password = '';
  versionnumber: string = environment.version_number;
  register = false;
  constructor(private registerService: RegisterService,
              private alertService: AlertService,
              private router: Router) { }

  ngOnInit() {
  }
  onSubmit() {
    this.register = true;
    const user = {name: this.name, email: this.email, password: this.password};
    this.registerService.register(user)
      .pipe(first()).subscribe(
        data => {
          this.register = false;
          this.alertService.success('Registration Successful');
          this.router.navigate(['/login']);
        },
        error => {
          this.register = false;
          this.alertService.error(error);
        }

    );
  }
}
