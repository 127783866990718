import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'app';
  activeBarIsVisible: boolean = false;
  hideMenuDirective: number = 0;
  checkShowMenu:boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  navigatePage($event: any) {
    if ($event == true) {
      this.checkShowMenu = true;
    }
  }

  hideSideBar() {
    this.hideMenuDirective++;
    if (this.hideMenuDirective > 3) {
      this.hideMenuDirective = 1;
    }
  }

  updateActiveBarStatus(barVisibilityStatus: boolean) {
    this.activeBarIsVisible = barVisibilityStatus;
  }

}
