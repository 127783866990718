import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { SuburbList } from '../models/data-models';
import { BaseHttpService } from './http/base/base-http.service';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GetSuburbsService extends BaseHttpService {

  constructor(private http: HttpClient) {
    super();
  }

  getAllSuburbs(): Observable<HttpResponse<Object>> {
    return this.http.get<HttpResponse<Object>>(this.baseurl + '/suburbs', {observe: 'response'} );
  }

  search(searchString: string): Observable<SuburbList[]> {
    return this.http.get<SuburbList[]>(this.baseurl + '/suburbs?name=' + searchString);
  }

  getSuburbById(id) {
    this.setResource('suburbs/' + id + '/edit' );
    return this.http.get(this.finalurl);
  }

  updateSuburb(suburb) {
    this.setResource('suburbs/' + suburb.id);
    return this.http.put(this.finalurl, suburb);
  }
}
