import { Injectable } from '@angular/core';
import { BaseHttpService } from './http/base/base-http.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { Project, ProjectDetails } from '../models/data-models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetProjectService extends BaseHttpService{

  selectedProjectToEdit: Project;
  constructor(private http:HttpClient) {
    super();
  }

  getProjects(suburbID): Observable<HttpResponse<object>>
  {
    if(suburbID==null){
      return this.http.get<HttpResponse<object>>(this.baseurl+"/projects", {observe: 'response'});
    } else {
      return this.http.get<HttpResponse<object>>(this.baseurl+"/projects/?suburbID="+suburbID, {observe: 'response'});
    }
  }

  search(searchString:string):Observable<Project[]>{
    return this.http.get<Project[]>(this.baseurl+"/projects?name="+searchString);
    
  }

  getProjectDetails(projectID):Observable<ProjectDetails>|null {
    if (projectID != null)
      return this.http.get<ProjectDetails>(this.baseurl + "/projects/"+projectID);

    return null;
  }

  setProject( project ) {
    this.selectedProjectToEdit = project;
  }

  get selectedProject() {
    return this.selectedProjectToEdit;
  }

  saveProject(project) {
    this.setResource('projects/' + project.projectId);
    return this.http.put(this.finalurl, project);
  }

  deleteProject(project) {
    this.setResource('projects/' + project.id);
    return this.http.delete(this.finalurl);
  }

}
