import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { GetProjectService } from '../../services/get-project.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProjectDetails } from '../../models/data-models';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})

export class ProjectCardComponent implements OnInit {

  currentSection=true;
  pastSection=false;
  reportsSection=false;
  
  showProjectCard=true;
  projectDetail:ProjectDetails;
  private propertysection =[
    {"item":"Property Details",   "active":"active"}, 
    {"item":"Customer Details",   "active":""}, 
    
        
    ];

    private selectedPropertySection;
  constructor(
                private route:ActivatedRoute, 
                private router:Router, 
                private getProjectService:GetProjectService ) {
    this.selectedPropertySection=this.propertysection[0];
   }


  ngOnInit() {
    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => {
        let projectID = params.get('id');
        return of(projectID);
    }))
    .subscribe(projectID=>{
        this.getProjectDetail(projectID);
    });
  }

  getProjectDetail(projectID){
    this.getProjectService.getProjectDetails(projectID)
    .subscribe((detail:ProjectDetails)=>{
      this.projectDetail=detail;
    })
  }

  currentInspection(){
    this.setSection("current");
  }

  pastInspections(){
    this.setSection("past");
  }

  inspectionReports(){
    this.setSection("reports");
  }

  setSection(sectionSet:string){
    this.currentSection=false;
    this.pastSection=false;
    this.reportsSection=false;
    if(sectionSet=="current"){
      this.currentSection=true;
    }
    if(sectionSet=="past"){
      this.pastSection=true;
    }
    if(sectionSet=="reports"){
      this.reportsSection=true;
    }
  }

  showDetails(item:string){
    
  }

  openProjectDetails(){
    if(this.projectDetail.projectid==undefined){
      this.router.navigate(["/projectdetail",0]);  
    }
    else
      this.router.navigate(["/projectdetail",this.projectDetail.projectid]);

  }

}
