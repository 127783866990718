import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-template-category',
  templateUrl: './add-template-category.component.html',
  styleUrls: ['./add-template-category.component.css']
})
export class AddTemplateCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
