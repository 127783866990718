
import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart } from '@angular/router';
import {NavigationService} from '../../services/navigation.service';

@Component({
  selector: 'app-activebar',
  templateUrl: './activebar.component.html',
  styleUrls: ['./activebar.component.css']
})
export class ActivebarComponent implements OnInit {
  toggleFlag = false;
  launchStatus = false;
  disabled: boolean;
  noActiveBarPages = ['login', 'signup', 'logout', 'splash'];
  showMenuIcon = false;
  private _showActiveBar: boolean;

  @Input('hideMenuFromBody') hideMenuFromBody: number;
  @Input('showActiveBar')
  set showActiveBar(showActiveBar: boolean) {
    this._showActiveBar = showActiveBar;
  }

  get showActiveBar(): boolean {return this._showActiveBar; }


  @Output('onActiveBarVisible') public  onActiveBarChange = new EventEmitter<boolean>();

  breadcrumbs: { display: String, link: String }[];

  constructor(private route: ActivatedRoute, private router: Router, private navService: NavigationService) {
  }

  ngOnInit() {

    this.breadcrumbs = this.navService.navigations;

    this.router.events
    .subscribe((event: NavigationEnd) => {
      if (event.url != undefined) {
        if ( event.url.indexOf('signup') >= 0 || event.url.indexOf('login') >= 0
          || event.url.indexOf('logout') >= 0 || event.url.indexOf('splash') >= 0
        || event.url.indexOf('resetPassword') >= 0){
          this.showActiveBar = false;
          this.showMenuIcon = false;
        } else {
          this.showActiveBar = true;
          if (event.url.indexOf('splash') >= 0) {
            this.showMenuIcon = false;
          } else {
            this.showMenuIcon = true;
          }
          // this.updateBreadCrumbs(event.url);
          this.breadcrumbs = this.navService.navigations;

        }
        this.onActiveBarChange.emit(this.showActiveBar);
       }
     });
  }

  ngOnChanges(hideMenu: { [propKey: string]: SimpleChange }) {
    let currentValue: number;
    if (hideMenu.hideMenuFromBody == undefined) {
      currentValue = 1;

    } else {
      currentValue = hideMenu.hideMenuFromBody.currentValue;
    }

    if (currentValue > 0) {
      this.closeMenu();
    }



  }

  updateBreadCrumbs(url: string) {
    if (url.indexOf('/splash') >= 0) {
      this.breadcrumbs = [];
      this.showMenuIcon = false;
    } else {
      this.showMenuIcon = true;
    }


    if (url.indexOf('/templates') >= 0) {
      this.breadcrumbs = [{ display: 'Templates', link: 'templates' }];
    }

    if (url.indexOf('/templateAdmin') >= 0 ) {
      this.breadcrumbs = [{ display: 'Templates', link: 'templates' }, { display: 'Template details', link: url }];
    }



    if (url.indexOf('/suburbs') >= 0) {
      this.breadcrumbs = [{ display: 'Suburbs', link: 'suburbs' }];
    }
    if (url.indexOf('/runningprojects') >= 0) {
      this.breadcrumbs = [{ display: 'Projects', link: 'projects' }];
    }
    if (url.indexOf('/propertiesbysuburb') >= 0) {
      this.breadcrumbs = [{ display: 'Suburbs', link: 'suburbs' },
      { display: 'Properties', link: url }];
    }

    if (url.indexOf('/property/details') >= 0) {
      this.breadcrumbs = [{ display: 'Suburbs', link: 'suburbs' },
        { display: 'Properties', link: 'propertiesbysuburb' },
        { display: 'Details', link: url}];
    }

    if (url.indexOf('/project/') >= 0) {
      this.breadcrumbs = [{ display: 'Suburbs', link: 'suburbs' },
        { display: 'Properties', link: 'propertiesbysuburb' },
        { display: 'Project', link: url}];
    }

    if (url.indexOf('/propertycard') >= 0) {
      if (this.breadcrumbs[0].display != 'Suburbs') {
        this.breadcrumbs.splice(0, 0, { display: 'suburbs', link: 'suburbs' });
        this.breadcrumbs.splice(2, 2, { display: 'Card', link: url });
      } else {
        this.breadcrumbs.splice(2, 1, { display: 'Card', link: url });
      }

    }

    if (url.indexOf('/projectcard') >= 0) {
      if (this.breadcrumbs[0].display == 'suburbs') {
        this.breadcrumbs.splice(0, 1);
        this.breadcrumbs.splice(2, 0, { display: 'project_card', link: url });
      }

    }

    if (url.indexOf('/users') >= 0) {
      this.breadcrumbs = [{ display: 'Users', link: url }];
    }

    if (url.indexOf('/users/edit') >= 0) {
      this.breadcrumbs = [{ display: 'Users', link: 'users' }, { display: 'Edit user', link: url }];
    }

    if (url.indexOf('/users/add') >= 0) {
      this.breadcrumbs = [{ display: 'Users', link: 'users' }, { display: 'Add user', link: url }];
    }
  }

  Launch($event: any) {
    event.stopImmediatePropagation();
    this.toggleFlag = !this.toggleFlag;
    this.launchStatus = this.toggleFlag;
    this.disabled = true;
  }

  closeMenu() {
    if (!this.disabled) {
      this.toggleFlag = false;
      this.launchStatus = this.toggleFlag;
    }
    this.disabled = false;

  }

  goTo(breadcrumb) {
    if (breadcrumb['link'] !== '') {
      this.router.navigate([breadcrumb['link']], {queryParams: breadcrumb['queryParams']});
    }
  }

}
