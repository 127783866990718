import { Component, OnInit } from '@angular/core';
import { GetSuburbsService} from '../../../services/get-suburbs.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService} from '../../../services/alert.service';
import { first, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-edit-suburb',
  templateUrl: './edit-suburb.component.html',
  styleUrls: ['./edit-suburb.component.css']
})
export class EditSuburbComponent implements OnInit {

  name = null;
  suburbId = null;
  loading = false;
  saving = false;

  constructor( private suburbService: GetSuburbsService,
               private router: Router,
               private route: ActivatedRoute,
               private alertService: AlertService) {
  }

  ngOnInit() {
    this.loading = true;
    const routerParams = this.route.snapshot.params;
    this.suburbId = routerParams.id;
    this.setSuburb();
  }

  setSuburb() {

    this.suburbService.getSuburbById(this.suburbId)
      .subscribe(
        data => {
          this.name = data['name'];
          this.loading = false;
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  updateSuburb() {
    this.saving = true;
    const suburb = {id: this.suburbId, name: this.name};
    this.suburbService.updateSuburb(suburb)
      .subscribe(
        data => {
          this.saving = false;
          this.alertService.success('Suburb Updated successfully');
          this.router.navigate(['/suburbs']);
        },
        error => {
          this.alertService.error(error);
          this.saving = false;
        }
      );
  }

}
