import { Component, OnInit } from '@angular/core';
import { TemplateMilestone } from '../../models/data-models';

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.css']
})
export class TemplateEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  saveTemplate(templatemilestone:TemplateMilestone){
    
  }
}
