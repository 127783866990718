import { Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable} from 'rxjs';

@Injectable()

export class RequestInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if ( !request.headers.get('Content-type')) {
    //   request = request.clone({
    //     headers: request.headers.set( 'Content-type', 'application/json')
    //   });
    // }
    return next.handle(request);
  }
}
