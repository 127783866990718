import { Component, OnInit } from '@angular/core';
import { SuburbList } from '../../models/data-models';
import { GetSuburbsService } from '../../services/get-suburbs.service';
import { AddEditSuburbsService } from '../../services/add-edit-suburbs.service';
import { AlertService} from '../../services/alert.service';
import { Router } from '@angular/router';
import {first, tap} from 'rxjs/operators';
import { NgxSmartModalService} from 'ngx-smart-modal';
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-suburbs',
  templateUrl: './suburbs.component.html',
  styleUrls: ['./suburbs.component.css'],
  providers: [GetSuburbsService]
})
export class SuburbsComponent implements OnInit {

  suburbs:any;
  filteredSuburbs: any;
  error:string="";
  dimmer:boolean=false;
  name= null;

  id:number=0;
  showSuburbList:boolean=true;
  deleteOption:boolean =false;
  deleteOptionConfirm:boolean;
  selectedSuburbToDelete: any = null;

  editOption:boolean=false;
  selectedSuburbToEdit: any = null;
  deletingSuburb = false;
  userRole: any;
  constructor(private suburbService:GetSuburbsService,
              private router:Router,
              private addEditSuburbService: AddEditSuburbsService,
              private alertService: AlertService,
              private modalService: NgxSmartModalService,
              private navService: NavigationService) {

    const breadcrumbs = [{display: 'Suburbs', link: 'suburbs'}];
    this.navService.setNavigation(breadcrumbs);

   }

  ngOnInit() {
    this.getSuburbs();
    this.assignCopy();
  }

  getSuburbs() {
    this.dimmer = true;
    this.suburbService.getAllSuburbs()
    .subscribe(data => {
      this.userRole = data.headers.get('User-Role');
      this.suburbs = this.filteredSuburbs = data.body;
      this.dimmer = false;
    },
    (error) => {
      this.error = error;
      this.dimmer = false;
      }
    );
  }



  navigateToProperties() {
    this.showSuburbList = false;
  }

  showEditOption(event){
    event.stopPropagation();
    this.editOption=!this.editOption;
    this.selectedSuburbToEdit=null;
    this.deleteOption=false;
  }
  showConfirmEditOption(suburb) {
    event.stopPropagation();
    this.selectedSuburbToEdit = suburb;
    this.router.navigate(['/editSuburb', this.selectedSuburbToEdit.id]);
  }

  showDeleteOption(event){
      event.stopPropagation();
      this.deleteOption=!this.deleteOption;
      this.deleteOptionConfirm=false;
      this.selectedSuburbToDelete=null;
      this.editOption=false;
  }
  showConfirmDeleteOption(suburb) {
    event.stopPropagation();
    this.selectedSuburbToDelete = suburb;
    this.confirmDeleteSuburb('deleteSuburb');
    // if (window.confirm('Are you sure you want to delete the suburb?')) {
    //   this.removeSuburb(this.selectedSuburbToDelete);
    // }
  }

  confirmDeleteSuburb(modal) {
    this.modalService.getModal(modal).open();
  }

  removeSuburb(suburb) {
    this.deletingSuburb = true;
    if (suburb.properties.length > 0) {
      this.alertService.error('This suburb contains properties. Cannot delete non empty suburbs');
      this.modalService.getModal('deleteSuburb').close();
      this.deletingSuburb = false;
      return;
    }
    this.addEditSuburbService.removeSuburb(suburb.id)
      .subscribe(
        data => {
          this.filteredSuburbs = this.suburbs.filter(u => u !== suburb);
          this.modalService.getModal('deleteSuburb').close();
          this.deletingSuburb = false;
        },
        error => {
          this.alertService.error(error.statusText);
          this.modalService.getModal('deleteSuburb').close();
          this.deletingSuburb = false;
        }
      );
  }

  navigateToProperty(clickedSuburb: SuburbList) {
    this.router.navigate(['/propertiesbysuburb', clickedSuburb.id], { queryParams: { suburb: clickedSuburb.name } });
  }

  filterSuburbs(suburb) {
    if (!suburb) {
      this.assignCopy();
    }
      this.filteredSuburbs = Object.assign([], this.suburbs).filter(
        item => item.name.toLowerCase().indexOf(suburb.toLowerCase()) > -1
      );
    }
  assignCopy() {
      this.filteredSuburbs = Object.assign([], this.suburbs);
  }

  clickDirector(selectedSuburb:SuburbList){
    if(this.deleteOption){
      this.showConfirmDeleteOption(selectedSuburb);
      return;
    }

    if(this.editOption){
      this.showConfirmEditOption(selectedSuburb);
      return;
    }

    this.navigateToProperty(selectedSuburb);
  }
}
