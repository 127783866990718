export class DataModels {
}

export class SuburbList {
    id:string;
    name:string;
    
}

export class PropertyList {
    id:string;
    name:string;
    suburbId:string;
    
}

export class User{
    id:string;
    name:string;
    fullName:string;
}


export class Project{
    id:string;
    name:string;
    start_date:Date;
    end_date:Date;
    duration:number;
    status:string;
    suburbid:string;
    property_id: number;
    property: Property;
    customer: Customer;
}

export class Property {
    id:number;
    name:string;
    
    suburbID:number;
    customer:Customer;
}


export class ProjectDetails{

    start_date:Date;
    end_date:Date;
    milestones:Milestones[];
    projectid:string;
}

export class Milestones{
   
    
    start_date?:Date;
    end_date?:Date;

    roll_up_progress_performance?:number; //roll up progress_performance

    estimated_duration?:number;
    actual_duration?:number;

    estimated_cost?:number;
    actual_cost?:number;

    roll_up_cost_performance?:number;
    
    tasklist?:Task[];
    taggedComments?:Comment[];
    taggedPictures?:Pictures[];
    projectid?:string;

    expanded?:string='closed';
    sortNum:number = null;

    constructor(public id:string,public name:string){
          this.expanded="closed";  
    }

    public toggleExpand(){
        this.expanded=this.expanded=='opened'?'closed':'opened';
    }
}

export class Task{
   
    progress?:number;
    start_date?:Date;
    end_date?:Date;


    estimated_duration?:number;
    actual_duration?:number;

    progress_performance?:number;


    estimated_cost?:number;
    actual_cost?:number;
    cost_performance?:number;

    taggedComments?:Comment[];
    taggedPictures?:Pictures[];
    visibility?:visibilityCloak;
    edit?:editCloak;
    
    //keys
    projectId?:string;
    milestoneId:string;

    //sortorder
    sortNum:number;


    active?:boolean;

    constructor(public id?:string,public name?:string){
            
    }

}



export class visibilityCloak{
    visibleToCustomer:boolean;
    visibleToSupervisors:boolean;
    supervisorGroups:string[];
}

export class editCloak{
    editableByCustomer:boolean;
    editableBySupervisors:boolean;
    supervisorGroups:string[];
}

export class Comments{
    id:string;
    text:string;

}

export class Pictures{
    id:string;
    name:string;
    url:string;
    dateCreated:Date;

}

export class Customer{
    id:string;
    name:string;
    
}


export class TemplateCategory{
    id:string;
    name:string;
}

export class Template{
   
    constructor(public id?:string, public name?:string, public categoryId?:string){

    }
}


export class TemplateMilestone extends Milestones{
    id:string;
    name:string;
    tasks?:TemplateTask[];
    sortNum:number;
    templateId:string;

    active:boolean;
   
}

export class TemplateTask extends Task{
  
    constructor(public templateId?:string){
        super();
        this.projectId=null; //For Templates inspectionID does not exist
        this.taggedPictures=null;
        this.taggedComments=null;


    }
}
