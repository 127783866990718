import { Component, OnInit } from '@angular/core';
import { GetPropertiesService } from '../../services/get-properties.service';
import { AlertService } from '../../services/alert.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PropertyList } from '../../models/data-models';
import {first, switchMap} from 'rxjs/operators';
import { Observable, of } from '../../../../node_modules/rxjs';
import {NgxSmartModalService} from "ngx-smart-modal";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-properties-by-suburb',
  templateUrl: './properties-by-suburb.component.html',
  styleUrls: ['./properties-by-suburb.component.css']
})
export class PropertiesBySuburbComponent implements OnInit {

  suburbId = null;
  suburb = null;
  dimmer:boolean=false;
  error:string="";

  selectedPropertyToEdit:PropertyList=null;
  selectedPropertyToDelete:PropertyList=null;
  deleteOption:boolean=false;
  deleteOptionConfirm:boolean=false;
  editOption:boolean=false;

  properties:PropertyList[];
  filteredProperties: PropertyList[];
  deleting = false;
  deleteModalId = 'deleteProperty';
  userRole: any;

  constructor(
    private route:ActivatedRoute,
    private propertyService:GetPropertiesService,
    private router:Router,
    private alertService: AlertService,
    private modalService: NgxSmartModalService,
    private  navService: NavigationService) {

    const breadcumbs = [
      {
        display: 'Suburbs',
        link: 'suburbs'
      },
      {
        display: this.route.snapshot.queryParams['suburb'],
        link: '',

      }
    ];
    this.navService.setNavigation(breadcumbs);
  }


  ngOnInit() {
    this.getPropertiesById();
  }
  getPropertiesById() {
    this.route.paramMap
      .pipe(switchMap((params: ParamMap) => {
        const suburbId = params.get('id');
        return of(suburbId);
      }))
      .subscribe(suburbId => {
        this.getProperties(suburbId);
      });
  }

  getProperties(suburbId) {
    this.suburbId = suburbId;
    this.dimmer = true;
    this.propertyService.getPropertiesBySuburbId(suburbId)
      .subscribe(data => {
          this.properties = this.filteredProperties = data['body']['properties'];
          this.userRole = data.headers.get('User-Role');
          this.suburb = data['body']['suburb']
          this.dimmer = false;
        },
        error => {
          this.error = error;
          this.dimmer = false;
        }
      );
  }

  clickDirector(property: PropertyList) {
    if (this.editOption) {
      this.showConfirmEditOption(property);
      return;
    }
    if (this.deleteOption) {
      this.showConfirmDeleteOption(property);
      return;
    }
    this.router.navigate(['/property/details', property.id],
      {queryParams: {suburbId: this.suburbId, property: property['address']}, queryParamsHandling: 'merge'});
  }

  showEditOption(event) {
    event.stopPropagation();
    this.editOption=!this.editOption;
    this.selectedPropertyToEdit=null;
    this.deleteOption=false;
  }

  showConfirmEditOption(property:PropertyList){
    this.selectedPropertyToEdit=property;
    this.router.navigate(['/suburb', this.suburbId, 'editProperty', this.selectedPropertyToEdit.id]);
  }

  showDeleteOption(event) {
    event.stopPropagation();
    this.deleteOption =! this.deleteOption;
    this.deleteOptionConfirm = false;
    this.selectedPropertyToDelete = null;
    this.editOption = false;
  }

  showConfirmDeleteOption( property ) {
    event.stopPropagation();
    this.selectedPropertyToDelete = property;
    this.confirmDelete(this.deleteModalId);
  }

  confirmDelete(modal) {
    this.modalService.getModal(modal).open();
  }

  removeProperty(property) {
    this.deleting = true;
    if (property.project.length > 0) {
      this.alertService.error('Property consists of project. Cannot delete non empty property');
      this.deleting = false;
      this.closeModal(this.deleteModalId);
      return;
    }
    this.propertyService.removeProperty(property.id)
      .subscribe(
        data => {
          this.filteredProperties = this.properties.filter(u => u !== property);
          this.deleting = false;
          this.closeModal(this.deleteModalId);
        },
        error => {
          this.deleting = false;
          this.alertService.error(error);
          this.closeModal(this.deleteModalId);
        }
      );
  }
  closeModal(id) {
    this.modalService.getModal(id).close();
  }

  filterProperties(property) {
    if (!property) {
      this.assignCopy();
    }
    this.filteredProperties = Object.assign([], this.properties).filter(
      item => (item.name.toLowerCase().indexOf(property.toLowerCase()) > -1
              || item.address.toLowerCase().indexOf(property.toLowerCase()) > -1)
    );
  }

  assignCopy() {
    this.filteredProperties = Object.assign([], this.properties);
  }
}
