import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-category-list',
  templateUrl: './template-category-list.component.html',
  styleUrls: ['./template-category-list.component.css']
})
export class TemplateCategoryListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
