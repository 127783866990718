import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {UserService} from "../../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../../../services/alert.service";

@Component({
  selector: 'app-edit-builder',
  templateUrl: './edit-builder.component.html',
  styleUrls: ['./edit-builder.component.css']
})
export class EditBuilderComponent implements OnInit {

  editUserForm: FormGroup;
  loading = false;
  userId = null;
  roles: any;
  properties: any;
  saving = false;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private alertService: AlertService) {

    this.editUserForm = this.fb.group({
      name: '',
      email: this.fb.control({ value: '', disabled: true })
    });
  }

  ngOnInit() {
    const routeParams = this.activeRoute.snapshot.params;
    this.userId = routeParams.userId;
    this.loading = true;
    this.setData();
  }

  setData() {
    const user = this.userService.selectedUserToEdit;
    this.editUserForm.patchValue({
      builderId : user['id'],
      name: user['name'],
      email: user['email']});
    this.loading = false;
  }

  updateBuilder() {
    this.saving  = true;
    const user = this.editUserForm.value;
    this.userService.updateBuilder(this.editUserForm.value, this.userId)
      .subscribe(
        data => {
          this.saving = false;
          this.router.navigate(['/builders']);
        },
        error => {
          this.saving = false;
          this.alertService.error(error);
        }
      );
  }


}
