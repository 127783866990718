import { Injectable } from '@angular/core';
import { BaseHttpService } from '../http/base/base-http.service';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {map, catchError, } from 'rxjs/operators';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthenticateService extends BaseHttpService {

  token_name = 'blank_one';
  userRole = null;

  isLoggedIn = false;
  jwt_token = '';
  user_name = '';
  private loggedInUser = null;
  constructor(private http: HttpClient) {
    super();
  }
  checkLogin(username, passwrd): Observable<any> {
    this.setResource('login');
    this.user_name = username;
    const body = {email: this.user_name, password: passwrd};
    return this.http.post(this.finalurl, body).pipe(map((data: string) => {
        if (data['token'].toLowerCase().indexOf('incorrect') >= 0) {
          this.isLoggedIn = false;
        } else {
          this.jwt_token = data['token'];
          this.userRole = data['userRole'];
          sessionStorage.setItem(this.token_name, this.jwt_token);
          sessionStorage.setItem('user-role', this.userRole);
          sessionStorage.setItem('userName', data['userName']);
          sessionStorage.setItem('isLoggedIn', 'true');
          sessionStorage.setItem('name', data['name']);
          sessionStorage.setItem('userId', data['userId']);
          this.isLoggedIn = true;
        }
        return this.isLoggedIn;
      }),
      catchError(this.handleError('error', []))

    );

  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<boolean> => {
      this.isLoggedIn = false;
      return of(this.isLoggedIn);
    };
  }
   getLoggedInUser() {
     this.loggedInUser = {name: sessionStorage.getItem('name'), userId: sessionStorage.getItem('userId')};
     return this.loggedInUser;
  }
  setLoggedInUser( user) {
    this.loggedInUser  = user;
  }

  validateCheckin(url: string): boolean {

    if (sessionStorage.getItem('isLoggedIn') === 'true') {
      return true;
    }
    if (this.isLoggedIn) {
      return true;
    }
    if (this.jwt_token != '') {
      this.isLoggedIn = true;
      return true;
    }
    if (environment.use_jwt) {
      return false;
    } else {
      return true;
    }
    // temp return true
  }

  validateState(state: boolean) {
    this.isLoggedIn = state;
  }

  getUser(): string {
    return this.user_name;
  }


  getJWTHeader() {
    const httpHeader = new HttpHeaders().set('Authorization', this.jwt_token);
    const header_options = {headers: httpHeader};
    return header_options;
  }

  forgotPassword(email) {
    this.setResource('resetPasswords');
    return this.http.post(this.finalurl, email);
  }

  resetPassword(password) {
    console.log(password);
    this.setResource('resetPasswords/' + password['token']);
    return this.http.put(this.finalurl, password);
  }
}
