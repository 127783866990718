import { Component, OnInit } from '@angular/core';
import { TemplateService} from '../../../services/template.service';
import { AlertService } from '../../../services/alert.service';
import { Router} from '@angular/router';
import { ActivatedRoute} from '@angular/router';
import { first} from 'rxjs/operators';


@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.css']
})
export class EditTemplateComponent implements OnInit {
  name = null;
  saving = false;
  templateId = null;

  constructor(private templateService: TemplateService,
              private alertService: AlertService,
              private router: Router,
              private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loadTemplate();
    const routeParams = this.route.snapshot.params;
    this.templateId = routeParams.templateId;
  }

  loadTemplate() {
    this.name = this.templateService.getSelectedTemplateToEdit().name;
  }

  saveTemplate() {
    this.saving = true;
    const template = {name: this.name};
    this.templateService.saveTemplate(template, this.templateId)
      .subscribe(
        data => {
          this.saving = false;
          this.router.navigate(['/templates']);
        },
        error => {
          this.alertService.error(error);
          this.saving = false;
        }
      );
  }

}
