import { Injectable } from '@angular/core';
import { BaseHttpService } from './http/base/base-http.service';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RequestOptions} from "@angular/http";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseHttpService {

  userToEdit = null;

  constructor( private http: HttpClient) {
    super();
  }

  getUsers() {
    this.setResource('users/getAllUsers');
    return this.http.get(this.finalurl);
  }


  getBuilders(): Observable<HttpResponse<object>> {
    this.setResource('builders');
    return this.http.get<HttpResponse<Object>>(this.finalurl, {observe: 'response'});
  }


  getRoles() {
    this.setResource('roles');
    return this.http.get(this.finalurl);
  }

  getProperties() {
    this.setResource('properties');
    return this.http.get(this.finalurl);
  }
  getUserProperties(user) {
    this.setResource('properties/user/' + user['id']);
    return this.http.get(this.finalurl);
  }
  getAvailableProperties(property) {
    this.setResource('properties?property=' + property);
    return this.http.get(this.finalurl);
  }

  saveUser(user) {
    this.setResource('users');
    return this.http.post(this.finalurl, user);
  }

  updateUser(user, userID) {
    this.setResource('users/' + userID);
    return this.http.put(this.finalurl, user);
  }

  updateBuilder(builder, userId) {
    this.setResource('builders/' + userId);
    return this.http.patch(this.finalurl, builder);
  }

  updateProfile(profile) {
    this.setResource('profile/' + profile['userId']);
    return this.http.patch(this.finalurl, profile);
  }

  changePassword(data) {
    this.setResource('password/' + data['userId']);
    return this.http.patch(this.finalurl, data);
  }

  setUsertoEdit(user) {
    this.userToEdit = user;
  }

  get selectedUserToEdit() {
    return this.userToEdit;
  }

  deleteUser(user) {
    this.setResource('users/' + user.id);
    return this.http.delete(this.finalurl);
  }
}
