import { Component, OnInit } from '@angular/core';
import { UserService} from "../../../services/user.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { AlertService } from "../../../services/alert.service";
import {debounceTime, distinctUntilChanged, first} from "rxjs/operators";
import { FormGroup, FormArray, FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  editUserForm: FormGroup;
  loading = false;
  userId = null;
  roles: any;
  properties: any = [];
  saving = false;
  assignedProperties: any = [];

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private alertService: AlertService,
  ) {
    this.editUserForm = this.fb.group({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: '',
      searchAvailableProperties: '',
      assignedProperties: this.fb.array([])
    });
  }

  ngOnInit() {
    const routeParams = this.activeRoute.snapshot.params;
    this.userId = routeParams.userId;
    this.loading = true;
    this.setData();
  }

  get userAssignedProperties() {
    return this.editUserForm.get('assignedProperties') as FormArray;
  }

  get searchProperty () {
    return this.editUserForm.get('searchAvailableProperties');
  }

  setData() {
    this.loading = true;
    const user = this.userService.selectedUserToEdit;
    this.userService.getRoles().pipe(first())
      .subscribe(
        roles => {
          this.roles = roles;
          this.setUserToEdit(user);
        },
      );
  }

  setUserToEdit(user) {
    this.editUserForm.patchValue({
      'name': user['name'],
      'email': user['email'],
      'role': user['role'] ? user['role']['id'] : '',
    });
    this.assignedProperties = user['properties'];
    this.assignedProperties.forEach(assignedProperty => {
      assignedProperty['checked'] = true;
      this.userAssignedProperties.push(
        this.fb.group({
          propertyId: assignedProperty['id'],
          chooseProperty: true
        })
      );
    })
    this.loading = false;
  }

  searchAvailableProperties(property) {
    this.userService.getAvailableProperties(property)
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(
      properties => {
        this.properties = properties;
        this.properties.forEach(availableProperty => {
          if (this.assignedProperties.filter(assignedProperty => assignedProperty['id'] == availableProperty['id']).length > 0) {
            availableProperty['checked'] = true;
          } else {
            availableProperty['checked'] = false;
          }
        });
      },
      error => {
        this.alertService.error('Something went wrong');
      }
    );
  }

  toggleCheckProperty(property) {
    property.checked = !property.checked;
    if (property.checked) {
      this.assignedProperties.push(property);
      this.userAssignedProperties.push(
        this.fb.group({
          propertyId: property['id'],
          chooseProperty: true
        })
      );
    } else {
      this.assignedProperties = this.assignedProperties.filter(u => u.id !== property.id);
      this.userAssignedProperties
        .removeAt(
          this.userAssignedProperties.value
            .findIndex(userAssginedProperty => userAssginedProperty.propertyId === property.id));
    }
    this.searchProperty.setValue('');
  }

  checkForAssignedProperties(properties, user) {
    this.editUserForm.patchValue({
      'name': user['name'],
      'email': user['email'],
      'role': user['role'] ? user['role']['id'] : '',
    });
    const control = <FormArray>this.editUserForm.controls.assignedProperties;

    properties.forEach(property => {
      const userPermission = user['permission'] ? user['permission'] : [];
      property['chooseProperty'] = false;
      if (userPermission.filter(permission => permission['property_id'] == property['id']).length > 0) {
        property['chooseProperty'] = true;
      }
      control.push(this.fb.group({
        propertyId: property['id'],
        propertyName: property['name'],
        chooseProperty: property['chooseProperty'],
      }));
    });
    this.loading = false;
  }

  saveUser() {
    this.saving = true;
    this.userService.updateUser(this.editUserForm.value, this.userId)
      .subscribe(
        data => {
          this.saving = false;
          this.alertService.success('Successfully updated');
          this.router.navigate(['/users']);
        },
        error => {
          this.saving = false;
          this.alertService.error(error);
        }
      );
  }


}

