import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {BaseHttpService} from '../http/base/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService extends BaseHttpService {

  constructor(private http: HttpClient) {
    super();
  }

  register(user) {
    this.setResource('builders');
    return this.http.post(this.finalurl, user, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    });
  }
}
