import { Injectable } from '@angular/core';
import {BaseHttpService} from "./http/base/base-http.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseHttpService{

  constructor(private http: HttpClient) {
    super();
  }

  getConfig() {
    this.setResource('settings');
    return this.http.get(this.finalurl);
  }

  saveConfig(config) {
    this.setResource('settings');
    return this.http.post(this.finalurl, config);
  }
}
