import { Component, OnInit } from '@angular/core';
import { Milestones, Task } from '../../models/data-models';
import { ActivatedRoute, Route, ParamMap } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const milestonesTemp=[
  new Milestones("1", "Scaffolding"),
  new Milestones("2", "Plumbing"),
  new Milestones("3", "Electrical"),
  new Milestones("4", "Painting"),
  new Milestones("5", "Roof"),
  

];

const taskTemp=[
  new Task("1", "Task 1"),
  new Task("2", "Task 2"),
  new Task("3", "Task 3"),
  new Task("4", "Task 4"),
  new Task("5", "Task 5")
  

];
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css'],
  providers:[ProjectService]
})
export class ProjectDetailsComponent implements OnInit {

  constructor(
    private route:ActivatedRoute,
    private projectService:ProjectService) { 

    }

  milestoneList: Milestones[];
  projectId:string;

  ngOnInit() {
    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => {
        let projectId= params.get('id');
        return of(projectId);
    }))
    .subscribe((projectId)=>{
        this.projectId=projectId;
        this.getProjectDetails();
    });


   
  }

  getProjectDetails() {
    this.projectService.getProjectDetails(this.projectId)
       .subscribe((data)=>{

          this.milestoneList=this.projectService.associateTasksToMilestones(data[0],data[1]);
      })
  }

  activeMilestone:Milestones;
  selectMilestone(clickedMileStone:Milestones){
    this.activeMilestone=clickedMileStone;
  }
}
