import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService} from "../../../services/alert.service";
import { ProjectService} from "../../../services/project.service";
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-add-milestones-project',
  templateUrl: './add-milestones-project.component.html',
  styleUrls: ['./add-milestones-project.component.css']
})
export class AddMilestonesProjectComponent implements OnInit {

  projectId = null;
  propertyId = null;
  adding = false;
  name: string = null;


  constructor(private projectService: ProjectService,
              private alertService: AlertService,
              private router: Router,
              private route: ActivatedRoute) {

    const routeParams = this.route.snapshot.params;
    this.projectId = routeParams.projectId;
    this.propertyId = routeParams.propertyId;
  }

  ngOnInit() {
  }

  addMilestone() {
    this.adding = true;
    const milestone = {name: this.name, project_id: this.projectId};
    this.projectService.addMilestone(milestone)
      .subscribe(
        data => {
          this.alertService.success('New Milestone Added');
          this.router.navigate(['/property', this.propertyId, 'project', this.projectId]);
          this.adding = false;
        },
        error => {
          this.alertService.error(error);
          this.adding = false;
        }
      );
  }

}
