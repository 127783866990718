import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./common/login/login.component";
import { SplashComponent } from "./common/splash/splash.component";
import { Authguard } from "./services/login/authguard";
import { SuburbsComponent } from "./views/suburbs/suburbs.component";
import { PropertiesBySuburbComponent } from "./views/properties-by-suburb/properties-by-suburb.component";
import { RunningProjectsComponent } from "./views/running-projects/running-projects.component";
import { ProjectCardComponent } from "./views/project-card/project-card.component";
import { TemplateListingComponent } from "./views/template-listing/template-listing.component";
import { TemplateComponent } from "./views/template/template.component";
import { TemplateEditComponent } from "./forms/template-edit/template-edit.component";
import { NoComponentFoundComponent } from "./common/no-component-found/no-component-found.component";
import { NgModule } from "@angular/core";
import { ProjectDetailsComponent } from "./views/project-details/project-details.component";
import { SignupComponent} from "./common/signup/signup.component";
import {TemplateCategoryListComponent} from "./views/template-category/template-category-list/template-category-list.component";
import {AddTemplateCategoryComponent} from "./views/template-category/add-template-category/add-template-category.component";
import {EditTemplateCategoryComponent} from "./views/template-category/edit-template-category/edit-template-category.component";
import {AddTemplateComponent} from "./views/template/add-template/add-template.component";
import {AddMilestoneComponent} from "./views/template/milestone/add-milestone/add-milestone.component";
import {EditMilestoneComponent} from "./views/template/milestone/edit-milestone/edit-milestone.component";
import {EditTemplateComponent} from "./views/template/edit-template/edit-template.component";
import { AddEditComponent} from "./views/suburbs/add-edit/add-edit.component";
import {AddEditPropertyComponent} from './views/properties-by-suburb/add-edit-property/add-edit-property.component';
import {EditPropertyComponent} from './views/properties-by-suburb/edit-property/edit-property.component';
import {EditSuburbComponent} from './views/suburbs/edit-suburb/edit-suburb.component';
import {PropertyCardComponent} from './views/properties-by-suburb/property-card/property-card.component';
import {CreateProjectComponent} from './views/project/create-project/create-project.component';
import {ViewProjectComponent} from './views/project/view-project/view-project.component';
import {AddMilestonesProjectComponent} from './views/project/add-milestones-project/add-milestones-project.component';
import {UserComponent} from './views/user/user.component';
import {AddUserComponent} from './views/user/add-user/add-user.component';
import {EditUserComponent} from './views/user/edit-user/edit-user.component';
import {EditProjectComponent} from './views/project/edit-project/edit-project.component';
import {BuilderComponent} from './views/builder/builder.component';
import {RegisterBuilderComponent} from './views/builder/register-builder/register-builder.component';
import {EditBuilderComponent} from './views/builder/edit-builder/edit-builder.component';
import {ProfileComponent} from './views/profile/profile.component';
import {SettingComponent} from './views/setting/setting.component';
import {ResetpasswordComponent} from './common/resetpassword/resetpassword.component';


// Routes with authentication enabled

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  // { path: 'signup', component: SignupComponent},
  { path: 'resetPassword/:token', component: ResetpasswordComponent},
  { path: 'splash', component: SplashComponent, canActivate: [Authguard]},
  { path: 'builders', component: BuilderComponent, canActivate: [Authguard]},
  { path: 'builders/add', component: RegisterBuilderComponent, canActivate: [Authguard]},
  { path: 'builders/edit/:userId', component: EditBuilderComponent, canActivate: [Authguard]},
  { path: 'suburbs', component: SuburbsComponent,  canActivate: [Authguard]},
  { path: 'addSuburb', component: AddEditComponent,  canActivate: [Authguard]},
  { path: 'editSuburb/:id', component: EditSuburbComponent, canActivate: [Authguard]},
  { path: 'suburb/:id/addProperty', component: AddEditPropertyComponent,  canActivate: [Authguard]},
  { path: 'suburb/:suburbId/editProperty/:propertyId', component: EditPropertyComponent, canActivate: [Authguard]},

  { path: 'users', component: UserComponent , canActivate: [Authguard]},
  { path: 'users/add', component: AddUserComponent , canActivate: [Authguard]},
  { path: 'users/edit/:userId', component: EditUserComponent , canActivate: [Authguard]},
  { path: 'profile', component: ProfileComponent, canActivate: [Authguard]},

  { path: 'propertiesbysuburb/:id', component: PropertiesBySuburbComponent,  canActivate: [Authguard]},
  { path: 'property/details/:propertyId', component: PropertyCardComponent, canActivate: [Authguard]},
  { path: 'property/:propertyId/project/add', component: CreateProjectComponent, canActivate: [Authguard]},
  { path: 'property/:propertyId/project/:projectId', component: ViewProjectComponent, canActivate: [Authguard]},
  { path: 'property/:propertyId/project/:projectId/add', component: AddMilestonesProjectComponent, canActivate: [Authguard]},

  { path: 'runningprojects', component: RunningProjectsComponent,  canActivate: [Authguard]},
  { path: 'projects', redirectTo: '/runningprojects', canActivate: [Authguard]},
  { path: 'project/edit/:projectId', component: EditProjectComponent, canActivate: [Authguard]},

  { path: 'projectcard/:id', component: ProjectCardComponent, canActivate: [Authguard]},
  { path: 'projectdetail/:id', component: ProjectDetailsComponent, canActivate: [Authguard]},

  { path: 'template-categories', component: TemplateCategoryListComponent,  canActivate: [Authguard]},
  { path: 'template-category/add', component: AddTemplateCategoryComponent,  canActivate: [Authguard]},
  { path: 'template-category/edit/:categoryId', component: EditTemplateCategoryComponent, canActivate: [Authguard]},
  { path: 'projectcard/:id', component: ProjectCardComponent, canActivate: [Authguard]},
  { path: 'projectdetail/:id', component: ProjectDetailsComponent, canActivate: [Authguard]},
  { path: 'templates', component: TemplateListingComponent, canActivate: [Authguard]},
  { path: 'templates/add', component: AddTemplateComponent, canActivate: [Authguard]},
  { path: 'templateAdmin/:id', component: TemplateComponent, canActivate: [Authguard]},
  { path: 'newtemplate/:id', component: TemplateEditComponent, canActivate: [Authguard]},
  { path: 'templates/edit/:templateId', component: EditTemplateComponent, canActivate: [Authguard]},
  { path: 'config', component: SettingComponent, canActivate: [Authguard]},


  { path: 'templateAdmin/:templateId/addMileStone', component: AddMilestoneComponent, canActivate: [Authguard]},
  { path: 'templateAdmin/:templateId/milestone/:milestoneId', component: EditMilestoneComponent, canActivate: [Authguard]},
  { path: 'templates/:id', redirectTo: '/newtemplate:id', canActivate: [Authguard]},
  { path: 'logout', redirectTo: '/login',  canActivate: [Authguard]},
  { path: '',   redirectTo: '/splash', pathMatch: 'full',  canActivate: [Authguard]},
  { path: '**',   component: NoComponentFoundComponent, canActivate: [Authguard]},

]


// Routes without authentication module for testing
// const appRoutes: Routes = [
//   { path: 'login', component: LoginComponent },
//   { path: 'signup', component: SignupComponent},
//   { path: 'splash', component: SplashComponent, },
//
//   { path: 'suburbs', component: SuburbsComponent,  },
//   { path: 'addSuburb', component: AddEditComponent,  },
//   { path: 'editSuburb/:id', component: EditSuburbComponent, },
//   { path: 'suburb/:id/addProperty', component: AddEditPropertyComponent,  },
//   { path: 'suburb/:suburbId/editProperty/:propertyId', component: EditPropertyComponent, },
//
//   { path: 'users', component: UserComponent , },
//   { path: 'users/add', component: AddUserComponent , },
//   { path: 'users/edit/:userId', component: EditUserComponent , },
//
//
//   { path: 'propertiesbysuburb/:id', component: PropertiesBySuburbComponent,  },
//   { path: 'property/details/:propertyId', component: PropertyCardComponent, },
//   { path: 'property/:propertyId/project/add', component: CreateProjectComponent, },
//   { path: 'property/:propertyId/project/:projectId', component: ViewProjectComponent, },
//   { path: 'property/:propertyId/project/:projectId/add', component: AddMilestonesProjectComponent, },
//
//   { path: 'runningprojects', component: RunningProjectsComponent,  },
//   { path: 'projects', redirectTo: '/runningprojects', },
//   { path: 'project/edit/:projectId', component: EditProjectComponent, },
//
//   { path: 'projectcard/:id', component: ProjectCardComponent, },
//   { path: 'projectdetail/:id', component: ProjectDetailsComponent, },
//
//   { path: 'template-categories', component: TemplateCategoryListComponent,  },
//   { path: 'template-category/add', component: AddTemplateCategoryComponent,  },
//   { path: 'template-category/edit/:categoryId', component: EditTemplateCategoryComponent, },
//   { path: 'projectcard/:id', component: ProjectCardComponent, },
//   { path: 'projectdetail/:id', component: ProjectDetailsComponent, },
//   { path: 'templates', component: TemplateListingComponent, },
//   { path: 'templates/add', component: AddTemplateComponent, },
//   { path: 'templateAdmin/:id', component: TemplateComponent, },
//   { path: 'newtemplate/:id', component: TemplateEditComponent, },
//   { path: 'templates/edit/:templateId', component: EditTemplateComponent, },
//
//
//   { path: 'templateAdmin/:templateId/addMileStone', component: AddMilestoneComponent, },
//   { path: 'templateAdmin/:templateId/milestone/:milestoneId', component: EditMilestoneComponent, },
//   { path: 'templates/:id', redirectTo: '/newtemplate:id', },
//   { path: 'logout', redirectTo: '/login',  },
//   { path: '',   redirectTo: '/splash', pathMatch: 'full',  },
//   { path: '**',   component: NoComponentFoundComponent, },
//
// ]

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: false, useHash: true}
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [Authguard],
})

export class ProjectRoutingModule {
}
