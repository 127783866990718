import { Task } from './../../models/data-models';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Milestones } from '../../models/data-models';

import { RouterState } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.css'],
  animations:[
    trigger('bodyExpansion', [
    state('closed', style({height: '0px', visibility: 'hidden'})),
    state('opened', style({height: '*', visibility: 'visible'})),
    transition('opened <=> closed', animate('50ms cubic-bezier(0.4,0.0,0.1,1)'))
  ])]
})
export class MilestoneComponent implements OnInit, OnChanges {
  @Input("milestone") milestone:Milestones;
  @Input("expand") expandFlag:boolean=false;
  @Output("selected") selected=new EventEmitter<boolean>();

  @Input("toggleMilestone") currentActiveMilestone:Milestones;
  

  toggleState:string="rotate(0deg)";
  

  constructor( ) { }

  ngOnInit() {
    if(this.expandFlag){
      this.milestone.expanded="closed";
    }
    else{
      this.milestone.expanded="opened";
    }
   

    this.toggleMilestoneState();
    this.setRAGStatus();
    
  }

  ngOnChanges(){
    
      if(this.currentActiveMilestone==this.milestone){

        this.toggleMilestoneState();
        
      }
      else{
   
         if(this.expandFlag ){
            this.milestone.expanded="closed";
          }
          else{
            this.milestone.expanded="opened";
          }
          this.toggleMilestoneState();
    }
      
 

    
  }
 
  expand(milestone){
    if(this.currentActiveMilestone === milestone){

      this.toggleMilestoneState();
      
    }
 
    if(this.milestone.expanded=='opened'){
      this.selected.emit(true);
    }
    else{

      this.selected.emit(false);
    }

   
   
   
  }

  _getExpandedState():string {
    return this.milestone.expanded=='opened' ? 'opened' : 'closed';
  }
  toggleMilestoneState(){
    this.milestone.toggleExpand();
    
    this.toggleState=this.milestone.expanded=='opened'?"rotate(0deg)":"rotate(180deg)";
  }


  activeTask:Task;
  selectActiveTask(selectedTask:Task){
    this.activeTask=selectedTask;
  }


  ragColor:string;
  progress_width:string;
  setRAGStatus(){

    if(this.milestone.roll_up_progress_performance>=90){
      this.ragColor="green";
      
    } 
    if(this.milestone.roll_up_progress_performance<90){
      this.ragColor="yellow";
      
    }
    if(this.milestone.roll_up_progress_performance<60){
      this.ragColor="red";
    }
    if(this.milestone.roll_up_progress_performance<=100){
      this.progress_width=this._getActualCompletionDaysInPercentage()+"%";
    }
    else{
      this.progress_width=this._getActualCompletionDaysInPercentage()+"%";
      this.ragColor="blue";
    }

  }


  _getActualCompletionDaysInPercentage(){
    return this.milestone.roll_up_progress_performance; //Change this to the (ED-SD)*progress %
  }
}
