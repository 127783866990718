import { Component, OnInit } from '@angular/core';
import { Template } from '../../models/data-models';
import { Router } from '@angular/router';
import { TemplateService } from '../../services/template.service';
import {first} from 'rxjs/operators';
import {AlertService} from '../../services/alert.service';
import {NgxSmartModalService} from "ngx-smart-modal";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-template-listing',
  templateUrl: './template-listing.component.html',
  styleUrls: ['./template-listing.component.css']
})
export class TemplateListingComponent implements OnInit {

  templates: any;
  filteredTemplates: any;
  error:string="";
  dimmer:boolean=false;

  id:number=0;
  showTemplateList:boolean=true;
  deleteOption:boolean =false;
  deleteOptionConfirm:boolean;
  selectedTemplateToDelete: Template;
  templateName = null
  editOption:boolean=false;
  selectedSuburbToEdit:Template;
  templateToDelete = null;
  chooseTemplateFromProject = false;
  deleteTemplateModalId = 'deleteTemplate';
  deleting = false;
  userRole: any;

  constructor(private templateService: TemplateService,
              private router: Router,
              private alertService: AlertService,
              private modalService: NgxSmartModalService,
              private navService: NavigationService) {

    const breadcrumbs = [
      {
        display: 'Templates',
        link: 'templates'
      }
    ];

    this.navService.setNavigation(breadcrumbs);
   }

  ngOnInit() {
    this.chooseTemplateFromProject = this.templateService.getChooseTemplateFromProject();
    this.getTemplates();
  }

  getTemplates() {
    this.dimmer = true;
    this.templateService.getTemplates()
    .subscribe((data) => {

      this.templates = this.filteredTemplates = data.body;
      this.userRole = data.headers.get('User-Role');
      this.dimmer=false;
    },
    (error)=>{
      this.error=error;
      this.dimmer=false;
      }
    );
  }

  navigateToProperties() {
    this.showTemplateList=false;
  }

  showEditOption(event) {
    event.stopPropagation();
    this.editOption=!this.editOption;
    this.selectedSuburbToEdit=null;
    this.deleteOption=false;
  }
  showConfirmEditOption(template) {
    this.templateService.setSelectedTemplateToEdit(template);
    this.router.navigate(['/templates/edit', template.id]);
  }

  showDeleteOption(event){
      event.stopPropagation();
      this.deleteOption=!this.deleteOption;
      this.deleteOptionConfirm=false;
      this.editOption=false;
  }
  showConfirmDeleteOption(template) {
    this.selectedTemplateToDelete = template;
    this.modalService.getModal(this.deleteTemplateModalId).open();
  }

  removeTemplate(template) {
    this.deleting = true;
      this.templateService.getTemplatesData(template.id)
        .subscribe(
          data => {
            this.templateToDelete = data;
            if (this.templateToDelete.milestones.length > 0) {
              this.alertService.error('The selected Template has milestones in it. Cannot delete the template!');
              this.deleting = false;
              return;
            }
            this.filteredTemplates = this.templates.filter(u => u !== template);
            this.templateService.deleteTemplate(template)
              .subscribe(
                data1 => {
                  this.alertService.success('Template Deleted');
                  this.deleting = false;
                  this.closeModal(this.deleteTemplateModalId);
                },
                error => {
                  this.alertService.error(error);
                  this.deleting = false;
                  this.closeModal(this.deleteTemplateModalId);
                }
              );
          }
        );
    }

    closeModal(id) {
    this.modalService.getModal(id).close();
    }

  navigateToProperty(clickedTemplate:Template){
    this.router.navigate(['/templateAdmin', clickedTemplate.id], {queryParams: {template: clickedTemplate.name}, queryParamsHandling: 'merge'});
  }

  filterTemplates(template) {
    if (!template) {
      this.assignCopy();
    }
    this.filteredTemplates = Object.assign([], this.templates).filter(
      item => item.name.toLowerCase().indexOf(template.toLowerCase()) > -1
    );
  }
  assignCopy() {
    this.filteredTemplates = Object.assign([], this.templates);
  }

  clickDirector(selectedTemplate:Template) {

    if (this.deleteOption) {
      this.showConfirmDeleteOption(selectedTemplate);
      return;
    }

    if (this.editOption) {
      this.showConfirmEditOption(selectedTemplate);
      return;
    }
    if (this.chooseTemplateFromProject) {
      // this.templateService.copyTemplateMilestone();
      this.dimmer = true;
      this.templateService.copyTemplateMilestoneAndTasks(selectedTemplate, this.chooseTemplateFromProject)
        .subscribe(
          data => {
            this.chooseTemplateFromProject = false;
            this.router.navigate(['/property', data, 'project', this.chooseTemplateFromProject]);
          },
          error => {
            this.chooseTemplateFromProject = false;
            this.alertService.error(error);
          }
        );
      return ;

    }

    this.navigateToProperty(selectedTemplate);
  }


}


