import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService} from '../../../../services/alert.service';
import { MilestoneService} from '../../../../services/milestone.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-add-milestone',
  templateUrl: './add-milestone.component.html',
  styleUrls: ['./add-milestone.component.css']
})
export class AddMilestoneComponent implements OnInit {

  name = null;
  templateId = null;
  adding = false;

  constructor(private milestoneService: MilestoneService,
              private alertService: AlertService,
              private router: Router,
              private route: ActivatedRoute
              ) { }

  ngOnInit() {

    const routeParams = this.route.snapshot.params;
    this.templateId = routeParams.templateId;
  }

  addMilestone() {
    this.adding = true;
    const milestone = {name: this.name, template_id: this.templateId};
    this.milestoneService.addMilestone(milestone)
      .subscribe(
        data => {
          this.alertService.success('New Milestone Added');
          this.router.navigate(['/templateAdmin', this.templateId]);
          this.adding = false;
        },
        error => {
          this.alertService.error(error);
          this.adding = false;
        }
      );
  }

}
