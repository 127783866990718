
import { Component, OnInit } from '@angular/core';
import { Template, TemplateMilestone,TemplateTask } from '../../models/data-models';
import { TemplateService } from '../../services/template.service';
import { AlertService } from "../../services/alert.service";
import { Router, ActivatedRoute } from '@angular/router';

import {first} from "rxjs/operators";
import {NgxSmartModalService} from "ngx-smart-modal";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {

  selectedItemType:string="milestone";
  templateInfo:Template;
  templateId:string;
  templateMilestones:TemplateMilestone[];

  milestoneSelected:TemplateMilestone;
  taskSelected:TemplateTask;
  editOption = false;
  deleteOption = false;

  selectedMilestoneId:string;

  showcopyMilestoneoption:boolean=false;
  error:string="";
  templates: any = {
    template: {
      name: ''
    },
    milestones: []
  };
  dimmer = false;
  sorting = false;
  deleteMilestoneModalId = 'deleteMilestone';
  deleting = false;
  milestoneCollapse: any;


  constructor(private templateService:TemplateService
              ,private activatedRoute:ActivatedRoute
              ,private router:Router
              ,private alertService: AlertService
              ,private modalService: NgxSmartModalService
              ,private navService: NavigationService) {

    const queryParams = this.activatedRoute.snapshot.queryParams;
    const breadcrumbs = [
      {
        display: 'Templates',
        link: 'templates'
      },
      {
        display: queryParams['template'],
        link: ''
      }
    ];
    this.navService.setNavigation(breadcrumbs);
  }

  ngOnInit() {
    this.dimmer = true;
    this.templateId = this.activatedRoute.snapshot.paramMap.get('id');
    // this.selectedMilestoneId=this.activatedRoute.snapshot.paramMap.get("milestoneid");
    // this.refreshData();
    this.getTemplates();
  }

  getTemplates() {
   this.templateService.getTemplatesData(this.templateId)
     .subscribe(
       data => {
        this.templates = data;
        this.dimmer = false;
       },
       error => {
         this.alertService.error(error);
         this.dimmer = false;
       }
     );
  }

  refreshData() {
    this.templateService.getTemplateData(this.templateId)
    .subscribe((data:any[])=>{
      
        let data_milestones= data[0];
        let data_tasks:TemplateTask[]=data[1];
        
        this.templateInfo=data[2];

        

        this.templateMilestones= this.templateService.associateTaskToMilestones(data_milestones, data_tasks);
        if(this.selectedMilestoneId!=null){
          let milestoneToSelect:TemplateMilestone=this.templateMilestones.filter(milestone=>this.selectedMilestoneId==milestone.id)[0];
          this.selectTemplateMilestone(milestoneToSelect, true);
        }
        
    });
  }


  addNewItem(){
      if(this.selectedItemType=="milestone"){
        this.router.navigate(['/templateAdmin/addmilestone',this.templateId]);
      }
      else{
        this.router.navigate(['/templateAdmin',this.templateId,"addtask",this.milestoneSelected.id]);
      }
  }

  addNewMilestone() {
    this.router.navigate(['/templateAdmin', this.templateId, 'addMileStone']);
  }


  saveMilestoneSort() {
    this.sorting = true;
    this.templateService.updateMilestoneSort(this.templates.milestones, this.templateId)
      .subscribe(
        data => {
       this.sorting = false;
       this.alertService.success('Successfully sorted');
        } ,
        error => {
          this.alertService.error(error);
          this.sorting = false;
        }
      );
  }
  editMilestone(milestone) {

    this.templateService.setSelectedMileStoneToEdit(milestone)
    this.router.navigate(['/templateAdmin', this.templateId, 'milestone', milestone.id]);
  }

  showEditOption(event) {
    event.stopPropagation();
    this.editOption = !this.editOption;
    this.deleteOption = false;
  }

  showDeleteOption(event) {
    event.stopPropagation();
    this.deleteOption = !this.deleteOption;
    this.editOption = false;
  }

  confirmDeleteMilestone(milestone) {
    this.milestoneSelected = milestone;
    this.modalService.getModal(this.deleteMilestoneModalId).open();
  }
  removeMilestone(milestone) {
    this.deleting = true;
    this.templates.milestones = this.templates.milestones.filter(u => u !== milestone);
    this.templateService.delteteMilestone(milestone)
      .subscribe(
        data => {
          this.alertService.success('Milesotne deleted');
          this.deleting = false;
          this.closeModal(this.deleteMilestoneModalId);
        },
        error => {
          this.alertService.error(error);
          this.deleting = false;
          this.closeModal(this.deleteMilestoneModalId);
        }
      );
  }

  collapseMilestone(index) {
    this.templates['milestones'][index]['collapse'] = !this.templates['milestones'][index]['collapse'];
  }

  closeModal(id) {
    this.modalService.getModal(id).close();
  }

  deleteMilestoneWithTasks(milestone) {
    this.templates.milestones = this.templates.milestones.filter(u => u !== milestone);
    this.templateService.delteteMilestone(milestone)
      .subscribe(
        data => {
          this.alertService.success('Milesotne deleted');
        },
        error => {
          this.alertService.error(error);
        }
      );


  }



  editSelectedItem() {
    if(this.milestoneSelected==null) return;
    
    if(this.taskSelected==null){
      this.router.navigate(['/templateAdmin', this.templateId,'editmilestone',this.milestoneSelected.id]);
    }
    else{
      this.router.navigate(['/templateAdmin',this.templateId,"milestones",this.milestoneSelected.id,"edittask",this.taskSelected.id]);
    }
  }

  selectTemplateMilestone(milestoneToSelect:TemplateMilestone, internalflag:boolean=false){
    
    if(milestoneToSelect==this.milestoneSelected && internalflag==false){
        this.milestoneSelected=null;
        this.taskSelected=null;
        this.selectedItemType="milestone";
        
    }
    else{
        this.milestoneSelected=null;
        this.milestoneSelected=milestoneToSelect;
        this.selectedItemType="task";
        this.taskSelected=null;
        
    }

    if(this.milestoneSelected!=null ){
      this.showcopyMilestoneoption=true;
    }
    else{
      this.showcopyMilestoneoption=false;
    }

    
  }
  
  copySelectedmilestone(){
  
    this.templateService.copyTemplateMilestone(this.milestoneSelected.id, this.milestoneSelected.templateId)
    .subscribe((milestoneCreated:TemplateMilestone)=>{
      this.templateMilestones.push(milestoneCreated);
     
      if(this.milestoneSelected.tasks!=null){
        this.templateService.getTemplateTasksByMilestone(this.milestoneSelected.id, this.milestoneSelected.templateId)
          .subscribe((tasks:TemplateTask[])=>{
              milestoneCreated.tasks=tasks;
          });
  
      }
      
    }, error=>{
      this.error=error;
    }
  
  )
   
    

  }

  selectTemplateTask(taskToSelect:TemplateTask, milestoneToSelect:TemplateMilestone){
      let temptask:TemplateTask=this.taskSelected;
      this.selectTemplateMilestone(milestoneToSelect, true);
      if (temptask==taskToSelect){
        this.taskSelected=null;
        this.selectedItemType="milestone";
      }
      else
        this.taskSelected=taskToSelect;

   }

   move (element, delta) {
    const array = this.templates.milestones;
    const index = array.indexOf(element);
    const newIndex = index + delta;
     if (newIndex < 0  || newIndex === array.length) {
       return;
     }
     const indexes = [index, newIndex].sort();
     this.templates.milestones.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);
   }

  moveUp(element) {
    this.move(element, -1);
  }

  moveDown(element) {
    this.move(element, 1);
  }

updateSort(){
  this.templateService.saveSort(this.templateMilestones)
   .subscribe((data)=>{
      this.refreshData();
  })
  
    
}

deleteSelectedItem(){
  if(this.taskSelected!=null){
    this.templateService.deleteTemplateTask(this.taskSelected)
    .subscribe((data:boolean)=>{
      if(data){
        let taskIndex=this.milestoneSelected.tasks.indexOf(this.taskSelected);
        if(taskIndex<this.milestoneSelected.tasks.length){
          this.milestoneSelected.tasks.splice(taskIndex,1);
        } 

      }
      
    })
  }
  else{
    this.templateService.deleteTemplateMilestone(this.milestoneSelected)
    .subscribe((data:boolean)=>{
        if(data){
          let milestoneIndex=this.templateMilestones.indexOf(this.milestoneSelected);
          if(milestoneIndex<this.templateMilestones.length){
              this.templateMilestones.splice(milestoneIndex,1);
          }
        }
    })
  }
}

}
