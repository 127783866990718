import {environment} from '../../../../environments/environment';


export class BaseHttpService {
  protected baseurl:string;
  protected origin: string;

  protected finalurl="";
  constructor() {
    this.baseurl=environment.apiEndPoint;
    // this.origin = environment.origin;
   }

   setResource(actualResource:string){
      this.finalurl =this.baseurl  +"/"+ actualResource;
   }
  getOrigin() {
    return this.origin;
  }
}
