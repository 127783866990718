import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})
export class SplashComponent implements OnInit {
  isOverlay:boolean;
  isVisible:boolean;
  selectedMenuItem:string;
  splashItems:{name:string,link:string,icon:string}[]=[

    {"name":"Suburbs","link":"suburbs","icon":"folder open"},
    {"name":"All Projects","link":"projects","icon":"building"},
    {'name' : 'Profile', 'link': 'profile', 'icon': 'id card outline'},
    {"name":"Logout","link":"logout","icon":"user"}

  ];

  constructor() {
    const currentUserRole = sessionStorage.getItem('user-role');
    if (currentUserRole === 'builder') {
      this.splashItems.splice(  2, 0, {'name': 'Templates', 'link': 'templates', 'icon': 'configure '});
      this.splashItems.splice( 3 , 0, {'name': 'Users', 'link': 'users', 'icon': 'user'});
      this.splashItems.splice(4, 0, {'name': 'Settings', 'link': 'config', 'icon': 'cogs'});
    }
    if (currentUserRole === 'admin') {
      this.splashItems = [
        {'name': 'Builders', 'link': 'builders', 'icon': 'user'},
        {'name' : 'Profile', 'link': 'profile', 'icon': 'id card outline'},
        {'name': 'Logout', 'link': 'logout' , 'icon' : 'user'}
      ];
    }
  }


  ngOnInit() {
    this.isOverlay = true;
    this.isVisible = true;
  }

}
